import { GoogleMap, MarkerF, LoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { useEffect, useState } from "react";
import { setKey, fromLatLng, fromPlaceId } from "react-geocode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Map_Api_Key = "AIzaSyB5Ym0SK9v490N-xdLo6qMHqBQ7fcT-NeU";
const libraries = ["places"];
// let prevSelectedCity = '';

// const Riyahd_BOUNDS = {
//   north: 25.1564724,
//   south: 24.2939113,
//   west:  46.2981033,
//   east: 47.34695430000001,
// };

function Map({
  setAddressProp,
  initCity,
  clickedCity,
  positionCord,
  setPositionCord,
  citiesKeys,
  location,
  changeCity,
  isUserCity,
}) {
  const [center, setCenter] = useState(
    positionCord ? positionCord : { lat: 24.7135517, lng: 46.6752957 }
  );
  const [CityBounds, setCityBounds] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  setKey(Map_Api_Key);
  const { t } = useTranslation();
  useEffect(() => {
    console.log(initCity);
    fromPlaceId(initCity)
      .then(({ results }) => {
        const { northeast, southwest } = results[0].geometry.bounds;
        setCityBounds({
          north: northeast.lat,
          south: southwest.lat,
          west: southwest.lng,
          east: northeast.lng,
        });
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (clickedCity && !isUserCity) {
      // console.log("clickedCity",clickedCity);
      getAddressByName(clickedCity, false);
    }
  }, [clickedCity]);

  const getCity = (addressArray) => {
    let city;
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "locality" === addressArray[i].types[0]) {
        city = addressArray[i].place_id;
        const { northeast, southwest } = addressArray[i].geometry.bounds;
        console.log(northeast, southwest);
        // setCenter(pos);

        // if (condition) {
        // setCityBounds({
        //   north: northeast.lat,
        //   south: southwest.lat,
        //   west:  southwest.lng,
        //   east: northeast.lng
        // })
        // }

        // console.log("city",city);
        return { city, northeast, southwest };
      }
    }
  };

  async function getUserLocation(value) {
    fromLatLng(value.lat, value.lng)
      .then(({ results }) => {
        console.log(results);
        const { city, northeast, southwest } = getCity(results);
        if (citiesKeys.includes(city)) {
          if (city !== clickedCity) {
            setCityBounds({
              north: northeast.lat,
              south: southwest.lat,
              west: southwest.lng,
              east: northeast.lng,
            });
            changeCity(city);
          }
          const pos = results[0].geometry.location;
          setPositionCord(pos);
          setAddressProp(results[0].formatted_address);
        } else {
          toast.error(t("jumpers don't serve your location"));
        }
      })
      .catch(console.error);
  }

  async function getAddressByName(value, flag = true) {
    fromPlaceId(value)
      .then(({ results }) => {
        console.log(results);
        const pos = results[0].geometry.location;
        setPositionCord(pos);
        if (flag) {
          setCenter(pos);
          setAddressProp(results[0].formatted_address);
        } else {
          console.log("===================null===========");
          setAddressProp("");
          const { northeast, southwest } = results[0].geometry.bounds;
          setCityBounds({
            north: northeast.lat,
            south: southwest.lat,
            west: southwest.lng,
            east: northeast.lng,
          });
        }
      })
      .catch(console.error);
  }

  async function getAddress(pos) {
    const result = await fromLatLng(pos.lat, pos.lng);
    setPositionCord(pos);
    setAddressProp(result.results[0].formatted_address);
    // getCity(result.results[0].address_components);
    console.log("res", result);
  }

  const handleMapClick = (e) => {
    const pos = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    getAddress(pos);
  };

  useEffect(() => {
    if (location) {
      return;
    }

    if (citiesKeys) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latLong = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            // getUserLocation({
            //   lat: 21.610547776305065,
            //   lng: 39.14879990122865,
            // });
            getUserLocation(latLong);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, [citiesKeys]);

  useEffect(() => {
    if (cityValue) {
      if (
        ["locality", "administrative_area_level_1", "country"].includes(
          cityValue.value.types[0]
        )
      ) {
        toast.error(`you should search for places in ${clickedCity}`);
      } else {
        console.log(cityValue);
        getAddressByName(cityValue.value.place_id);
      }
    }
  }, [cityValue]);

  useEffect(() => {
    if (CityBounds) {
      setCenter(positionCord);
    }
  }, [CityBounds]);

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={Map_Api_Key}
      libraries={libraries}
    >
      <GooglePlacesAutocomplete
        selectProps={{
          cityValue,
          onChange: setCityValue,
        }}
        autocompletionRequest={{
          bounds: [
            { lat: CityBounds?.south, lng: CityBounds?.west },
            { lat: CityBounds?.north, lng: CityBounds?.east },
          ],
          componentRestrictions: {
            country: ["sa"],
          },
        }}
      />
      <div style={{ width: "100%", height: "500px" }}>
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={12}
          onClick={(e) => {
            handleMapClick(e);
          }}
          options={{
            restriction: {
              latLngBounds: CityBounds,
            },
          }}
        >
          <MarkerF position={positionCord} />
        </GoogleMap>
      </div>
    </LoadScript>
  );
}

export default Map;
