import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errEqual, setErrEqual] = useState("");

  const validatePass = () => {
    if (newPassword !== confirmNewPassword) {
      setErrEqual("Confirm password must match password");
    } else {
      setErrEqual("");
    }
  };

  const updatePassword = () => {
    if (newPassword === confirmNewPassword) {
      axios
        .put(
          "https://api.jumpersksa.online/users/update_password",
          {
            password: password,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
          },
          {
            headers: {
              "content-type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          toast.success(t("Password Updated Successfully!"), {
            position: toast.POSITION.TOP_LEFT,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setErrEqual(t("Confirm password must match password"));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    validatePass();
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    validatePass();
  };

  return (
    <div className="mx-auto max-w-270">
      <div className="w-full sm:w-1/3">
        <label htmlFor="password">{t("Old Password")}</label>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>

      <div className="w-full sm:w-1/3">
        <label htmlFor="newPassword">{t("New Password")}</label>
        <input
          type="password"
          value={newPassword}
          onChange={handleNewPasswordChange}
        />
      </div>

      <div className="w-full sm:w-1/3">
        <label htmlFor="confirmNewPassword">{t("Confirm New Password")}</label>
        <input
          type="password"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
        />
        {errEqual && <p className="text-red">{errEqual}</p>}
      </div>

      <button onClick={updatePassword}>{t("Change Password")}</button>
    </div>
  );
};

export default ChangePassword;
