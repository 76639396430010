import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../images/logo/logo (1).png";
import { usePDF } from "react-to-pdf";
import { useParams } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../utilis/formateDate";
import QRCode from "react-qr-code";
import Invoice from "../Invoice";
import Loader from "../../common/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InvoiceDetails = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.user);
  const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });
  const componentRef = useRef();
  const [systemInfo, setSystemInfo] = useState("");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Base_URL = "https://api.jumpersksa.online/";
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${Base_URL}invoices/user_invoice/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
          // Authorization:
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTAyOGYwMDgzYjdmZWFhNDk5YjIyYmYiLCJ1c2VyUm9sZSI6ImNsaWVudCIsImlhdCI6MTY5NzQ0MzgxNH0.EOJw3hVOKNcMSTYvare_sM_WI8KZr9sUCy4cFvBeEgo",
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);

  useEffect(() => {
    axios
      .get(`${Base_URL}systemInfo`, {
        headers: {
          //   Authorization: localStorage.getItem("token"),
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTAyOGYwMDgzYjdmZWFhNDk5YjIyYmYiLCJ1c2VyUm9sZSI6ImNsaWVudCIsImlhdCI6MTY5NzQ0MzgxNH0.EOJw3hVOKNcMSTYvare_sM_WI8KZr9sUCy4cFvBeEgo",
        },
      })
      .then((res) => {
        console.log(res);
        setSystemInfo(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="inline-flex gap-x-2">
        <button
          onClick={() => toPDF()}
          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
        >
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
          </svg>
          {t("Invoice PDF")}
        </button>
        <button
          onClick={handlePrint}
          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
        >
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
          </svg>
          {t("Print")}
        </button>
      </div>

      <div ref={componentRef}>
        <div
          className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-4"
          ref={targetRef}
        >
          {/* Grid */}
          <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
            <div>
              <div className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                <img width={100} src={Logo} alt="Logo" />
              </div>
            </div>
            <div>
              {userInfo?.isCompany ? (
                <>
                  <div className="text-2xl font-bold">{t("Tax invoice")}</div>
                </>
              ) : (
                <>
                  <div className="text-2xl font-bold">
                    {t("Simplified tax invoice")}
                  </div>
                </>
              )}
            </div>
            {/* Col */}
            <div className="inline-flex gap-x-2">
              <div className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                <QRCode
                  size={80}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`${Base_URL}${data?.qrCode}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}
          {/* Grid */}
          <div className="grid md:grid-cols-2 gap-3">
            {/* Col */}
            <div>
              <div className="grid space-y-3">
                <p className="min-w-[150px] max-w-[200px] text-xl font-bold text-gray-500 mb-0">
                  {t("Billed to")} :
                </p>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Company name")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {t("Jumpers")}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Phone Number")}
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {data?.billTo?.phone}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Email")}
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {data?.billTo?.email}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Commercial register")} (CR):
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {data?.billTo?.commRegister}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Vat number")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {data?.billTo?.taxNumber}
                  </dd>
                </dl>
                <p className="min-w-[150px] max-w-[200px] text-xl font-bold text-gray-500 mb-0">
                  {t("Billed from")} :
                </p>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Client Informations")}:
                  </dt>
                  <dd className="text-gray-800 dark:text-gray-200">
                    <p className=" items-center gap-x-1.5 text-blue-600 decoration-2 font-medium capitalize">
                      {data?.client?.firstName} {data?.client?.lastName}
                    </p>{" "}
                    <p className="block items-center gap-x-1.5 text-blue-600 decoration-2 font-medium">
                      {data?.client?.email}{" "}
                    </p>
                    <p className="block items-center gap-x-1.5 text-blue-600 decoration-2 font-medium">
                      {data?.client?.phoneNumber}{" "}
                    </p>
                  </dd>
                </dl>
                {data?.client?.isCompany && (
                  <dl className="grid sm:flex gap-x-3 text-sm">
                    <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                      {t("Client Company Info")}:
                    </dt>

                    <dd className="text-gray-800 dark:text-gray-200">
                      <p className=" items-center gap-x-1.5 text-blue-600 decoration-2 font-medium capitalize">
                        {data?.client?.organization?.name}{" "}
                      </p>{" "}
                      <p className="block items-center gap-x-1.5 text-blue-600 decoration-2 font-medium">
                        {data?.client?.organization?.address}{" "}
                      </p>
                      <p className="block items-center gap-x-1.5 text-blue-600 decoration-2 font-medium">
                        {t("Zip Code")}: {data?.client?.organization?.zipCode}{" "}
                      </p>
                      <p className="block items-center gap-x-1.5 text-blue-600 decoration-2 font-medium">
                        {t("Commercial register")}(CR):{" "}
                        {data?.client?.organization?.commRegister}{" "}
                      </p>
                    </dd>
                  </dl>
                )}

                {/*<dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    Site Ratio:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    % {data?.order?.siteRatio}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    Vat Ratio:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    % {data?.order?.taxRatio}
                  </dd>
                                    </dl>*/}
              </div>
            </div>
            <div>
              <div className="grid space-y-3">
                <p className="min-w-[150px] max-w-[200px] text-xl font-bold text-gray-500 mb-0">
                  {t("Invoice Informations")}:
                </p>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Invoice number")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {data?.invoiceNumber}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Due date")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {formatDate(data?.createdAt)}
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Currency")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {t("SAR")}
                  </dd>
                </dl>{" "}
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    {t("Billing method")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    {t("Send invoice")}
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}
          <>
            {" "}
            <p className="min-w-[150px] max-w-[350px] text-xl font-bold text-gray-500 mt-5">
              {t("Invoice Details")}
            </p>
            <div className="overflow-x-auto">
              <table className="w-full table-auto overflow-x-auto mt-3 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <thead className="text-center">
                  <tr className="bg-gray-2 dark:bg-meta-4 text-center">
                    <th className="max-w-[80px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Service Name")}
                    </th>
                    {/* <th className="max-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Description")}
                    </th> */}
                    <th className="max-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Quantity")}
                    </th>
                    <th
                      className="max-w-[120px] py-4 px-4 font-medium text-black dark:text-white"
                      title="this price including jumper price in all days"
                    >
                      {t("Jumper Price")}
                    </th>
                    <th className="max-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Total")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.order?.jumpers?.map((jumper) => (
                    <tr key={jumper?.jumper?._id}>
                      <td className="max-w-[80px] border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          {data?.order?.service?.name}
                        </p>
                      </td>
                      {/* <td className="max-w-[100px] border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          Providing employees to offer {t("")}
                          {data?.order?.service?.name} service.
                        </p>
                      </td> */}
                      <td className="max-w-[100px] border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="not-italic font-normal">
                          {Number(data?.order?.jumpers?.length)}
                        </p>
                      </td>
                      <td className="max-w-[100px] border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="not-italic font-normal">
                          {Number(
                            data?.order?.timeAndCost?.jumperPrice *
                              data?.order?.timeAndCost?.dayNo
                          )}{" "}
                          {t("SAR")}{" "}
                        </p>
                      </td>
                      <td className="max-w-[120px] border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        {Number(
                          data?.order?.timeAndCost?.jumperPrice *
                            data?.order?.timeAndCost?.dayNo *
                            data?.order?.jumpers?.length
                        )}{" "}
                        {t("SAR")}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>

          {/* Flex */}
          <div className="mt-8 flex sm:justify-end">
            <div className="w-full max-w-2xl sm:text-right space-y-2">
              {/* Grid */}
              <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500">{t("Subtotal")}:</dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                    {t("SAR")}{" "}
                    {Number(data?.order?.totalJumpersPrice).toFixed(2)}
                  </dd>
                </dl>
                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500">
                    VAT ({data?.order?.taxRatio}%):
                  </dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                    {t("SAR")} {Number(data?.order?.taxPrice).toFixed(2)}
                  </dd>
                </dl>
                <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
                  <dt className="col-span-3 text-gray-500">
                    {t("Total With VAT")}:
                  </dt>
                  <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                    {t("SAR")} {Number(data?.total).toFixed(2)}
                  </dd>
                </dl>
              </div>
              {/* End Grid */}
            </div>
          </div>
          {/* End Flex */}
        </div>
      </div>
    </>
  );
};

export default InvoiceDetails;
