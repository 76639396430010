import Breadcrumb from "../../components/Breadcrumb";
import { IoIosAddCircleOutline } from "react-icons/io";

import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TiTrash } from "react-icons/ti";
import { useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { AiOutlinePhone } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setUser } from "../../Store/Actions/userActions";
import { BsFileEarmarkCheckFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FaCircleQuestion } from "react-icons/fa6";

const UpdateProfile = () => {
  const { t, i18n } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const [cityNew, setcityNew] = useState(
    userInfo?.city?._id ? userInfo?.city?._id : ""
  );
  const [nationalityNew, setnationalityNew] = useState(
    userInfo?.nationality ? userInfo?.nationality : "saudi"
  );
  const [genderNew, setgenderNew] = useState(
    userInfo?.gender ? userInfo?.gender : "male"
  );
  const [aboutNew, setaboutNew] = useState(
    userInfo?.about ? userInfo?.about : ""
  );
  const dispatch = useDispatch();
  const data = localStorage.getItem("role");
  const [selectedDate, setSelectedDate] = useState(null);
  const [userAge, setUserAge] = useState(userInfo?.age);
  const [birthDate, setBirthDate] = useState(userInfo?.birthOfDate);
  useEffect(() => {
    if (birthDate) {
      const today = new Date();
      const newBD = new Date(birthDate);
      const age = today?.getFullYear() - newBD?.getFullYear();
      setUserAge(age);
    }
  }, [birthDate]);
  const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    if (date) {
      setBirthDate(new Date(date).toLocaleString());
    } else {
      setUserAge(null);
    }
  };
  const [colorMode, setColorMode] = useState();

  const [fullName, setFullName] = useState();
  const [firstName, setFirstName] = useState(
    userInfo?.firstName ? userInfo?.firstName : ""
  );
  const [lastName, setLastName] = useState(
    userInfo?.lastName ? userInfo?.lastName : ""
  );
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [phone, setPhone] = useState();
  const [about, setAbout] = useState();
  const [resCity, setResCity] = useState(userInfo?.city ? userInfo?.city : "");
  const [nationalityA, setNationality] = useState();
  const [skills, setSkills] = useState([]);
  const [age, setAge] = useState("");
  const Age = useRef();
  const phoneA = useRef();
  const gender = useRef();
  const nationality = useRef();
  const About = useRef();
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let birthdate = userInfo?.birthOfDate?.toLocaleString();
  if (birthdate) {
    birthdate = birthdate.split("T")[0];
    console.log(birthdate); // Output: 1999-07-25
  }
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/users/user_data`, {
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setFirstName(res?.data?.data?.firstName);
        setLastName(res?.data?.data?.lastName);
        setEmail(res?.data?.data?.email);
        setRole(res?.data?.data?.role);
        setFullName(
          res?.data?.data?.firstName + " " + res?.data?.data?.lastName
        );
        setPhone(res?.data?.data?.phoneNumber);
        setAge(res?.data?.data?.age);
        setAbout(res?.data?.data?.about);
        setNationality(res?.data?.data?.nationality);
        setSkills(res?.data?.data?.skills);
        setResCity(res?.data?.data?.city?._id);
        console.log(skills);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(birthdate);
  const newSkills = skills.filter((s) => {
    return s.isActive;
  });
  console.log(newSkills);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(resCity);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/city`, {
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: "all",
        },
      })
      .then((res) => {
        console.log(res);
        setCities(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const updateProfile = () => {
    axios
      .put(
        `https://api.jumpersksa.online/users/update_profile`,
        {
          firstName: firstName,
          lastName: lastName,
          age: userAge, // Use userAge here
          birthOfDate: birthDate,
          nationality: nationalityNew,
          gender: genderNew,
          about: aboutNew,
          // phoneNumber: phoneA?.current?.value,
          city: cityNew,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(setUser(res?.data?.data));
        toast.success(t("Profile Updated Successfully!"));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(userInfo);

  const handleRemoveSkill = async (skillID, index) => {
    try {
      const updatedSkills = [...skills];
      updatedSkills.splice(index, 1);
      setSkills(updatedSkills);
      const response = await axios.delete(
        `https://api.jumpersksa.online/users/remove_skill/${skillID}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      console.log(`Skill with ID ${skillID} removed.`);
      dispatch(setUser(response.data.user));
      toast.success(t("skill removed succefully!"));
    } catch (error) {
      console.error(`Error removing skill with ID ${skillID}:`, error);
    }
  };
  const [services, setServices] = useState();
  console.log(services);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `https://api.jumpersksa.online/jumperServices/user_services?limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
            // lang: "all",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setServices(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://api.jumpersksa.online/jumperServices?jumper=${userInfo?._id}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setServices(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  const handleDeleteService = async (serviceID, index) => {
    try {
      const updatedServices = [...services];
      updatedServices.splice(index, 1);
      setServices(updatedServices);
      await axios.delete(
        `https://api.jumpersksa.online/jumperServices/${serviceID}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      console.log(`Skill with ID ${serviceID} removed.`);
      toast.success(t("service removed succefully!"));
    } catch (error) {
      console.error(`Error removing skill with ID ${serviceID}:`, error);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const formdata = new FormData();

  // Function to handle file input
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setSelectedFile(file);
  };
  const handleUpload = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (
        fileExtension === "pdf" ||
        fileExtension === "doc" ||
        fileExtension === "docx" ||
        fileExtension === "PDF" ||
        fileExtension === "DOC" ||
        fileExtension === "DOCX"
      ) {
        formdata.append("cv", selectedFile);
        console.log("Selected File:", selectedFile);
        axios
          .put(`https://api.jumpersksa.online/users/upload_cv`, formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log(res);
            toast.success(t("CV updated successfully!"));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("Please select a PDF or DOC/DOCX file to upload.");
      }
    } else {
      alert("Please select a file to upload.");
    }
  };

  const editService = (id) => {
    navigate(`/editservice/${id}`);
  };
  console.log(city);
  const handleNavCheck = () => {
    if (
      !userInfo?.birthOfDate ||
      !userInfo?.city ||
      !userInfo?.about ||
      !userInfo?.gender ||
      !userInfo?.nationality
    ) {
      toast.warning(
        t(
          "Please complete and save Your data first before navigating to other page!"
        )
      );
    } else {
      navigate("/addnewservice");
    }
  };
  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Edit Profile")} />

        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark flex justify-between items-center">
                <h3 className="font-bold text-black dark:text-white">
                  {t("Personal Information")}
                </h3>
              </div>
              <div className="p-7">
                <form action="#">
                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="firstName"
                      >
                        {t("First Name")}
                      </label>
                      <div className="relative">
                        <span className="absolute left-4.5 top-4">
                          <svg
                            className="fill-current"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                                fill=""
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                                fill=""
                              />
                            </g>
                          </svg>
                        </span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="text"
                          name="firstName"
                          id="firstName"
                          defaultValue={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          // readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="lastName"
                      >
                        {t("Last Name")}
                      </label>
                      <div className="relative">
                        <span className="absolute left-4.5 top-4">
                          <svg
                            className="fill-current"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.72039 12.887C4.50179 12.1056 5.5616 11.6666 6.66667 11.6666H13.3333C14.4384 11.6666 15.4982 12.1056 16.2796 12.887C17.061 13.6684 17.5 14.7282 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0655C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0655C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7282 2.93899 13.6684 3.72039 12.887Z"
                                fill=""
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.99967 3.33329C8.61896 3.33329 7.49967 4.45258 7.49967 5.83329C7.49967 7.214 8.61896 8.33329 9.99967 8.33329C11.3804 8.33329 12.4997 7.214 12.4997 5.83329C12.4997 4.45258 11.3804 3.33329 9.99967 3.33329ZM5.83301 5.83329C5.83301 3.53211 7.69849 1.66663 9.99967 1.66663C12.3009 1.66663 14.1663 3.53211 14.1663 5.83329C14.1663 8.13448 12.3009 9.99996 9.99967 9.99996C7.69849 9.99996 5.83301 8.13448 5.83301 5.83329Z"
                                fill=""
                              />
                            </g>
                          </svg>
                        </span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={(e) => setLastName(e.target.value)}
                          defaultValue={lastName}
                          // readOnly
                        />
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="phoneNumber"
                      >
                        {t("Phone Number")}
                      </label>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        ref={phoneA}
                        defaultValue={phone}
                        placeholder={phone}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="emailAddress"
                      >
                        {t("Email Address")}
                      </label>
                      <div className="relative">
                        <span className="absolute left-4.5 top-4">
                          <svg
                            className="fill-current"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                                fill=""
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                                fill=""
                              />
                            </g>
                          </svg>
                        </span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="email"
                          name="emailAddress"
                          id="emailAddress"
                          defaultValue={email}
                          readOnly
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="emailAddress"
                      >
                        {t("select your birthdate")}{" "}
                        <span
                          className="text-danger text-lg absolute mx-2"
                          title={t("Required")}
                        >
                          *
                        </span>
                      </label>
                      <div className="relative">
                        <span className="absolute left-4.5 top-4">
                          <svg
                            className="fill-current"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.33301 4.16667C2.87658 4.16667 2.49967 4.54357 2.49967 5V15C2.49967 15.4564 2.87658 15.8333 3.33301 15.8333H16.6663C17.1228 15.8333 17.4997 15.4564 17.4997 15V5C17.4997 4.54357 17.1228 4.16667 16.6663 4.16667H3.33301ZM0.833008 5C0.833008 3.6231 1.9561 2.5 3.33301 2.5H16.6663C18.0432 2.5 19.1663 3.6231 19.1663 5V15C19.1663 16.3769 18.0432 17.5 16.6663 17.5H3.33301C1.9561 17.5 0.833008 16.3769 0.833008 15V5Z"
                                fill=""
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.983719 4.52215C1.24765 4.1451 1.76726 4.05341 2.1443 4.31734L9.99975 9.81615L17.8552 4.31734C18.2322 4.05341 18.7518 4.1451 19.0158 4.52215C19.2797 4.89919 19.188 5.4188 18.811 5.68272L10.4776 11.5161C10.1907 11.7169 9.80879 11.7169 9.52186 11.5161L1.18853 5.68272C0.811486 5.4188 0.719791 4.89919 0.983719 4.52215Z"
                                fill=""
                              />
                            </g>
                          </svg>
                        </span>

                        <DatePicker
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          selected={selectedDate}
                          defaultValue={userInfo?.birthOfDate}
                          onChange={handleDateChange}
                          maxDate={new Date()}
                          required
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          dateFormat="dd/MM/yyyy"
                          placeholderText={`${
                            userInfo?.birthOfDate
                              ? birthdate
                              : t("Select your birthdate")
                          }`}
                        />
                      </div>
                      {/* {ageError && <p className="text-red">{ageError}</p>} */}
                    </div>
                    <div>
                      <label
                        className="mb-3 block text-sm font-bold text-black dark:text-white"
                        htmlFor="emailAddress"
                      >
                        {t("Your Age")}
                      </label>
                      <div className="relative">
                        <span className="absolute left-4.5 top-4"></span>
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="number"
                          name="age"
                          id="age"
                          defaultValue={userInfo?.age}
                          ref={Age}
                          disabled
                        />
                      </div>
                      {/* {ageError && <p className="text-red">{ageError}</p>} */}
                    </div>
                  </div>

                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("Nationality")}{" "}
                      <span
                        className="text-danger text-lg absolute mx-2"
                        title={t("Required")}
                      >
                        *
                      </span>
                    </label>

                    <select
                      value={nationalityNew}
                      name="nationality"
                      id="nationality"
                      onChange={(e) => {
                        setnationalityNew(e.target.value);
                      }}
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    >
                      <option value="saudi">{t("Saudi")}</option>
                      <option value="other">{t("Other")}</option>
                    </select>
                  </div>
                  <div className="mb-5.5">
                    {showDetails && (
                      <div
                        className="flex justify-end relative trans"
                        // onMouseMove={() => setShowDetails(true)}
                        // onMouseLeave={() => setShowDetails(false)}
                      >
                        <div
                          onClick={() => {
                            setShowDetails(false);
                          }}
                          className="bg-black relative bg-opacity-30 trans text-white duration-300 ease-in-out dark:bg-body transition-all w-fit rounded-sm p-2 cursor-pointer"
                        >
                          {t("default city")}
                          <span
                            onClick={() => {
                              setShowDetails(false);
                            }}
                            className="absolute -right-2 -top-1 rounded-full bg-danger bg-opacity-50 text-white font-bold text-xs w-4 text-center"
                          >
                            X
                          </span>
                        </div>
                      </div>
                    )}
                    <label
                      className="mb-3 text-sm font-bold text-black dark:text-white flex justify-between items-center"
                      htmlFor="Username"
                    >
                      {t("City")}
                      <FaCircleQuestion
                        className="cursor-pointer trans transition-all duration-300 ease-in-out"
                        onClick={() => setShowDetails(true)}
                      />
                      <span
                        className="text-danger text-lg absolute mx-12 flex justify-center items-center"
                        title={t("Required")}
                      >
                        *
                      </span>
                    </label>

                    <select
                      onChange={(e) => {
                        setcityNew(e?.target?.value);
                      }}
                      value={cityNew}
                      name="city"
                      id="city"
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    >
                      <option disabled value={""}>
                        select city
                      </option>
                      {cities.map((c, i) => (
                        <option value={c?._id} key={c?._id}>
                          {i18n.language === "ar" ? c?.name_ar : c?.name_en}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("Gender")}
                      <span
                        className="text-danger text-lg absolute mx-2"
                        title={t("Required")}
                      >
                        *
                      </span>
                    </label>

                    <select
                      name="gender"
                      onChange={(e) => {
                        setgenderNew(e.target.value);
                      }}
                      // ref={genderN}
                      value={genderNew}
                      id="gender"
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                    >
                      <option value="male">{t("Male")}</option>
                      <option value="Female">{t("Female")}</option>
                    </select>
                  </div>

                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("About")}
                      <span
                        className="text-danger text-lg absolute mx-2"
                        title={t("Required")}
                      >
                        *
                      </span>
                    </label>
                    <div className="relative">
                      <span className="absolute left-4.5 top-4">
                        <svg
                          className="fill-current"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8" clipPath="url(#clip0_88_10224)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                              fill=""
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                              fill=""
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_88_10224">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <textarea
                        className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="bio"
                        id="bio"
                        rows={6}
                        onChange={(e) => {
                          setaboutNew(e.target.value);
                        }}
                        placeholder={t("Write your bio here")}
                        defaultValue={aboutNew}
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-center w-full gap-4.5">
                    {/* <button
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-bold text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                      type="submit"
                    >
                      {t("Cancel")}
                    </button> */}
                    <button
                      className="flex justify-center rounded w-full bgteal py-2 px-6 font-bold text-gray hover:shadow-1"
                      type="submit"
                      disabled={isButtonDisabled}
                      onClick={(e) => {
                        e.preventDefault();
                        updateProfile();
                      }}
                    >
                      {t("Save")}
                    </button>
                  </div>

                  {/* <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded border border-stroke py-2 px-6 font-bold text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                      type="submit"
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="flex justify-center rounded bgteal py-2 px-6 font-bold text-gray hover:shadow-1"
                      type="submit"
                      disabled={isButtonDisabled}
                      onClick={(e) => {
                        e.preventDefault();
                        updateProfile();
                      }}
                    >
                      {t("Save")}
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        {data === "jumper" && (
          <>
            <div className="grid grid-cols-3 gap-8 mt-7">
              <div className="col-span-5 xl:col-span-3">
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                  <div className="border-b border-stroke py-4 px-7 dark:border-strokedark flex justify-between items-center">
                    <h3 className="font-bold text-black dark:text-white">
                      {t("CV, Skills and Services")}
                    </h3>
                  </div>

                  <div className="mb-5.5 px-8 pt-4 ">
                    <label
                      className="mb-3 block text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("your CV|Resume")}
                      <span
                        className="text-danger text-lg absolute mx-2"
                        title={t("Required")}
                      >
                        *
                      </span>
                    </label>

                    <div>
                      <div className="relative flex justify-between">
                        <div className="w-full flex">
                          <label
                            htmlFor="cvFile"
                            className="cursor-pointer flex justify-between relative w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          >
                            <input
                              type="file"
                              accept=".pdf, .doc, .docx"
                              id="cvFile"
                              onChange={handleFileChange}
                              className="sr-only" // Hide the default file input
                            />
                            <div className="flex items-center justify-between">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 mr-2 sm:h-8 sm:w-8 md:h-10 md:w-10" // Adjust icon size
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                              </svg>
                              {t("Choose File")}
                            </div>
                          </label>
                        </div>

                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          {userInfo?.cv ? (
                            <BsFileEarmarkCheckFill
                              fontSize={25}
                              className="text-success"
                            />
                          ) : (
                            <BsFileEarmarkExcelFill
                              fontSize={25}
                              className="text-danger"
                            />
                          )}
                        </span>
                      </div>

                      {selectedFile && (
                        <div>
                          <p className="mt-2">
                            {t("Selected File")}: {selectedFile?.name}
                          </p>
                          <button
                            onClick={handleUpload}
                            className="flex justify-center rounded bgteal py-2 px-6 font-bold text-gray hover:shadow-1"
                          >
                            {t("Upload CV")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-5.5 px-8 pt-4 ">
                    <label
                      className="mb-3 flex justify-between items-center text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("Skills")}{" "}
                      <span
                        className="text-danger text-lg absolute mx-14"
                        title={t("Required")}
                      >
                        *
                      </span>
                      <div onClick={handleNavCheck}>
                        <span
                          title={t("Add New Service")}
                          className="cursor-pointer"
                        >
                          <IoIosAddCircleOutline fontSize={25} />
                        </span>
                      </div>
                    </label>
                    {userInfo?.skills?.length > 0 ? (
                      <div className="overflow-auto">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-100 ">
                            <tr className="">
                              <th class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("No.")}
                              </th>
                              <th class=" py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("Skill Name")}
                              </th>
                              <th class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full rounded text-center border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary">
                            {newSkills?.map((s, index) => (
                              <tr key={s?._id} id="borderOfService">
                                <td class="px-6 py-4 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td class=" py-4 whitespace-nowrap">
                                  {s?.name}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap justify-center flex ">
                                  <button
                                    class="text-red-500 hover:text-red-700"
                                    onClick={() =>
                                      handleRemoveSkill(s?._id, index)
                                    }
                                  >
                                    <TiTrash className="w-6 h-6 mx-3 text-danger" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center w-full">
                          <div className="message-container message-container-two  rounded-md p-5 w-full">
                            {t("you can add skills from")}{" "}
                            <div
                              onClick={handleNavCheck}
                              className="text-[#1f6b1b] cursor-pointer inline-block font-bold"
                            >
                              {t("Services and Skills")}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="mb-5.5 px-8 pt-4 ">
                    <label
                      className="mb-3 flex justify-between items-center text-sm font-bold text-black dark:text-white"
                      htmlFor="Username"
                    >
                      {t("Services")}{" "}
                      <span
                        className="text-danger text-lg absolute mx-14"
                        title={t("Required")}
                      >
                        *
                      </span>
                      <div onClick={handleNavCheck}>
                        <span
                          title={t("Add New Service")}
                          className="cursor-pointer"
                        >
                          <IoIosAddCircleOutline fontSize={25} />
                        </span>
                      </div>
                    </label>
                    {services?.length > 0 ? (
                      <div className="overflow-auto">
                        <table class="min-w-full divide-y text-center divide-gray-200 overflow-auto">
                          <thead class="bg-gray-100 text-center">
                            <tr>
                              <th class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("No.")}
                              </th>
                              <th class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("Service Name")}
                              </th>
                              <th class="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">
                                {t("Actions")}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full text-center rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary">
                            {services?.map((sv, index) => (
                              <tr key={sv?._id} id="borderOfService">
                                <td class="px-6 py-4 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td class="px-6 py-4 ">{sv?.service?.name}</td>
                                <td class="px-6 py-4  flex justify-center items-center h-full w-full">
                                  <button class="text-red-500 hover:text-red-700">
                                    <TiTrash
                                      className="w-6 h-6 text-danger"
                                      onClick={() => {
                                        handleDeleteService(sv?._id, index);
                                      }}
                                    />
                                  </button>
                                  <button
                                    class="text-red-500 hover:text-red-700"
                                    onClick={() => {
                                      editService(sv?._id);
                                    }}
                                  >
                                    <FaRegEdit className="w-6 h-6 mx-3 text-secondary" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center w-full">
                          <div className="message-container message-container-two rounded-md p-5 w-full">
                            {t("you can add services from")}{" "}
                            <div
                              onClick={handleNavCheck}
                              className="text-[#1f6b1b] cursor-pointer inline-block font-bold"
                            >
                              {t("Services and Skills")}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UpdateProfile;
