import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import OrderingSteps from "../../components/OrderingSteps";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../axiosInst";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ClientReviewAndPublication = ({ socket }) => {
  const { t, i18n } = useTranslation();
  const order = useSelector((state) => state.order);
  const { userInfo } = useSelector((state) => state.user);

  const [city, setCity] = useState();
  console.log(order);
  const navigate = useNavigate();

  const handleClick = async () => {
    const res = await axiosInstance.post("/orders", order, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    toast.success(t("Order created Successfully"));
    sendNotofication(order?.jumpers, userInfo?.firstName, userInfo?.lastName);
    socket.emit("update offers", order?.jumpers);
    navigate(`/order/${res?.data?.order?._id}`);
  };
  const sendNotofication = (jumpers, fname, lname) => {
    const notification = {
      users: jumpers,
      type: "offer",
      title_en: "New Offer",
      desc_en: `you've recived new offer check it out`,
      title_ar: "عرض جديد",
      desc_ar: "لقد تلقيت عرض جديد يرجى التحقق منه",
      createdAt: Date.now(),
    };
    socket.emit("new notfication", notification);
  };

  const fetchCity = async () => {
    const result = await axiosInstance.get(`/city/${order.orderInfo.city}`);
    setCity(result.data.data);
    console.log(result.data.data);
  };

  useEffect(() => {
    axiosInstance.defaults.headers.common["lang"] = i18n.language;
    fetchCity();
  }, [i18n.language]);
  const changeNameOfGender = (name) => {
    switch (name) {
      case "all":
        return t("All");
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("All");
    }
  };
  const changeNameOfNationalities = (name) => {
    switch (name) {
      case "all":
        return t("All Nationalities");
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("All Nationalities");
    }
  };
  return (
    <>
      <OrderingSteps step1 step2 step3 step4 />
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Order Inforamtions Reviewing")} />

        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Order Inforamtions Reviewing")}
                </h3>
              </div>
              <div className="p-7">
                <div class="grid grid-cols-1 divide-y ">
                  <div class="flex flex-row justify-between pb-3">
                    <div>
                      <p>{t("Service Name")}</p>
                    </div>
                    <div>
                      <p>{order?.serviceName}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Number Of Jumbers")}</p>
                    </div>
                    <div>
                      <p>{order?.jumpersOfferNumber}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Nationality")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfNationalities(
                          order.orderInfo.JumpersNationality
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Gender")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfGender(order?.orderInfo.JumpersGender)}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("City")}</p>
                    </div>
                    <div>
                      <p>{city?.name_en}</p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Address")}</p>
                    </div>
                    <div>
                      <p>{order?.orderInfo.address}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Age Range")}</p>
                    </div>
                    <div>
                      <p>
                        From {order?.orderInfo.JumpersAgeMin} To{" "}
                        {order?.orderInfo.JumpersAgeMax}{" "}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Skills")}</p>
                    </div>
                    <div>
                      {order?.skillName.map((s) => (
                        <p>{s.label}</p>
                      ))}
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Work Description")}</p>
                    </div>
                    <div>
                      <p>{order?.workDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Timing And Costing Reviewing")}
                </h3>
              </div>
              <div className="p-7">
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Hours")}</p>
                  </div>
                  <div>
                    <p>
                      {order?.timeAndCost.workHours} {t("hours")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Days")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost.dayNo}</p>
                  </div>
                </div>

                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Start Date")}</p>
                  </div>
                  <div>
                    <p>{order.startDate.toLocaleString("en-AU")}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("End Date")}</p>
                  </div>
                  <div>
                    <p>{order.endDate.toLocaleString("en-AU")}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Suggested Cost")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost.packagePrice}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Jumper Price per day")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost.packagePrice}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Jumper Price")}</p>
                  </div>
                  <div>
                    <p>
                      {order?.timeAndCost?.jumperPrice *
                        order?.timeAndCost?.dayNo}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Total Jumpers Price")}</p>
                  </div>
                  <div>
                    <p>
                      {order?.timeAndCost?.jumperPrice *
                        order?.timeAndCost?.dayNo *
                        order?.jumpersOfferNumber}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("VAT Cost")}</p>
                  </div>
                  <div>
                    <span className="font-bold mx-2">(15%)</span>
                    {""}
                    <span>
                      {0.15 *
                        order?.timeAndCost?.jumperPrice *
                        order?.timeAndCost?.dayNo *
                        order?.jumpersOfferNumber}
                    </span>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Total Cost with VAT")}</p>
                  </div>
                  <div>
                    <p>
                      {order?.timeAndCost?.jumperPrice *
                        order?.timeAndCost?.dayNo *
                        order?.jumpersOfferNumber +
                        0.15 *
                          order?.timeAndCost?.jumperPrice *
                          order?.timeAndCost?.dayNo *
                          order?.jumpersOfferNumber}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4.5">
            <button
              className="flex w-full justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
              type="submit"
              onClick={handleClick}
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientReviewAndPublication;
