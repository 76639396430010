import React, { useEffect, useState } from "react";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { usePDF } from "react-to-pdf";
import { MdOutlineAssignment } from "react-icons/md";
import "./Contract.css";
import { FaFilePdf } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../common/Loader";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const Contract = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [offer, setOffer] = useState();
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/offers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setOffer(res?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);
  const { toPDF, targetRef } = usePDF({ filename: "jumpers-contract.pdf" });
  const date = new Date();
  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="contract">
        <Breadcrumb pageName={t("Contract")} />
        <div className="contractContainer" ref={targetRef}>
          <div className="header">
            <div className="title">
              <p className="pTitle">
                {/* <p>نَموذَج تَعَاقُد</p> */}
                <p>{t("Contract Format")}</p>
              </p>
            </div>
            <div className="dateAndTime">
              <p>
                {" "}
                {t("Date of today")} : {date.toLocaleString()}
              </p>
            </div>
            <hr className="m-2" />
          </div>
          <div className="dataOfOneAndTwo m-2">
            <div className="dataOfOne">
              <p className="my-3 font-semibold dataTitle">
                {t("First Party Information")} ({t("Client")}):
              </p>
              <div className="dataDetails">
                <p>
                  {t("Full Name")}: {offer?.client?.firstName}{" "}
                  {offer?.client?.lastName}
                </p>
                <p>
                  {t("Email")}: {offer?.client?.email}
                </p>
                <p>
                  {t("Phone Number")}: {offer?.client?.phoneNumber}
                </p>
                <p>
                  {t("Type of Service")}: {offer?.order?.service?.name}
                </p>
                <p>
                  {t("Total Price")}: {offer?.order?.totalPrice} {t("SAR")}
                </p>
              </div>
            </div>
            <div className="dataOfTwo">
              <p className="my-3 font-semibold dataTitle">
                {t("Second Party Information")} ({t("Jumper")}):
              </p>
            </div>
            <div className="dataDetails">
              <p>
                {t("Full Name")}: {offer?.jumper?.firstName}{" "}
                {offer?.jumper?.lastName}
              </p>
              <p>
                {t("Age")}: {offer?.jumper?.age}
              </p>
              <p>
                {t("Email")}: {offer?.jumper?.email}
              </p>
              <p>
                {t("Phone Number")}: {offer?.jumper?.phoneNumber}
              </p>
              <p>
                {t("Nationality")}: {offer?.jumper?.nationaltiy}
              </p>
              <p>
                {t("Type of Service")}: {offer?.order?.service?.name}
              </p>
              {/* <p>
                {t("Work Hours")}: {offer?.order?.timeAndCost?.workHours}
              </p>
              <p>
                {t("Work Days")}: {offer?.order?.timeAndCost?.dayNo}
              </p> */}
              <p>
                {t("Total Price")}:{" "}
                {offer?.order?.timeAndCost?.dayNo *
                  offer?.order?.timeAndCost?.jumperPrice}{" "}
                {t("SAR")}
              </p>
              <small className="text-xs">
                {t(
                  "The Site Ratio and the tax Ratio are deducted from the amount."
                )}
              </small>
              {/* <p>
                {t("Site Ratio")}: {offer?.order?.siteRatio}%
              </p>
              <p>
                {t("Tax Price")}: {offer?.order?.taxRatio}%
              </p> */}
            </div>
            <div className="ContractFormat">
              <p className="my-3 font-semibold dataTitle">
                {t("Contract Format Details")} :
              </p>
            </div>
            <div className="dataDetails">
              <div
                dangerouslySetInnerHTML={{
                  __html: offer?.interface?.description,
                }}
                className="flex flex-col justify-center items-center gap-x-1.5 text-blue-600 decoration-2 font-medium"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttonsContainer flex justify-center">
        <div className="buttons flex justify-center">
          <button onClick={() => toPDF()} className="flex items-center">
            {t("Download as")} <FaFilePdf className="mx-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contract;
