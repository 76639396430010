import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SetReview from "../../components/SetReview/SetReview";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const JumperReview = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [order, setOrder] = useState([]);
  const [favList, setFavList] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/orders/user_order/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setOrder(res.data.data);
        setFavList(res.data.data.client.favList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addToFav = (jumperID) => {
    axios
      .post(
        `https://api.jumpersksa.online/users/add_fav`,
        {
          jumper: jumperID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success("jumper added succefully to favourites");
        setFavList([...favList, jumperID]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeFromFav = (jumperID) => {
    axios
      .delete(`https://api.jumpersksa.online/users/remove_fav/${jumperID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("jumper removed succefully to favourites");
        const newFavList = favList.filter((f) => {
          return f !== jumperID;
        });
        setFavList(newFavList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[50px] py-4 px-1 font-medium text-black dark:text-white xl:pl-11">
                  {t("NO.")}
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  {t("Jumber Name")}
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  {t("Jumper Image")}
                </th>
                <th className="py-4 px-4 font-medium text-black dark:text-white">
                  {t("service")}
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  {t("Add to favourites")}
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  {t("set review")}
                </th>
              </tr>
            </thead>
            <tbody className="tableSelectJumpers">
              {order?.jumpers?.map((j, i) => (
                <tr className="text-start">
                  <td className="border-b border-[#eee] py-5 px-1 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      {i + 1}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {`${j.jumper?.firstName} ${j.jumper?.lastName}`}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      <img
                        // src={
                        //   j?.cover
                        //     ? `https://api.jumpersksa.online${j?.cover}`
                        //     : "a"
                        // }
                        src="https://windows11clone.netlify.app/Assets/user.png"
                        alt="jumper-cover"
                        width={80}
                        height={80}
                        className="rounded-full"
                      />
                    </p>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="inline-flex m-1 rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                      {order?.service?.name}
                    </p>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark text-center  ">
                    <di className="flex justify-center items-center">
                      {favList?.includes(j?.jumper?._id) ? (
                        <AiFillHeart
                          color="crimson"
                          fontSize={25}
                          onClick={() => removeFromFav(j?.jumper?._id)}
                        />
                      ) : (
                        <AiOutlineHeart
                          fontSize={25}
                          onClick={() => addToFav(j?.jumper?._id)}
                        />
                      )}
                    </di>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark ">
                    <SetReview
                      jumperId={j.jumper._id}
                      orderId={order._id}
                      jumperRating={j.rating}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JumperReview;
