import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import CoverOne from "../../images/cover/cover-01.png";
import userSix from "../../images/user/default.png";
import axios from "axios";
import { useState } from "react";
import { axiosInstance } from "../../axiosInst";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { BsPersonVideo2 } from "react-icons/bs";
import { AiTwotoneStar } from "react-icons/ai";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { AddJumper, rmvJumper } from "../../Store/Actions/orderJumpersActions";

const JumperProfile = () => {
  console.log("JumperProfilerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const { t, i18n } = useTranslation();
  const data = localStorage.getItem("role");
  const { userInfo } = useSelector((state) => state.user);
  const Base_URL = "https://api.jumpersksa.online/";
  const { id } = useParams();
  const [firstName, setFirstName] = useState();
  const dispatch = useDispatch();

  const [services, setServices] = useState([]);

  const [cv, setCv] = useState();
  const selectedJumpers = useSelector((state) => state.orderJumpers);

  const addJumpers = () => {
    dispatch(AddJumper(services?.jumper?._id));
    toast.success(t("jumper added successfully"));
  };

  const undoAddJumpers = () => {
    dispatch(rmvJumper(services?.jumper?._id));
    toast.success(t("jumper removed successfully"));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jumperServicesResponse = await axios.get(
          `${Base_URL}jumperServices/${id}`,
          {
            headers: {
              lang: i18n.language,
            },
          }
        );
        console.log(jumperServicesResponse);
        setServices(jumperServicesResponse.data.data);
        setCv(jumperServicesResponse?.data?.data?.jumper?.cv);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [i18n.language, id]);
  return (
    <>
      <Breadcrumb pageName={t("Jumper Profile")} />

      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="relative z-20 h-35 md:h-65">
          <img
            src={CoverOne}
            alt="profile cover"
            className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
          />
        </div>

        <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
          <div className="relative z-30 mx-auto -mt-22 h-30 w-full max-w-30 rounded-full bg-white/20 p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
            <div className="relative drop-shadow-2">
              <div className="divImg">
                <img
                  src={
                    services?.jumper?.image
                      ? `${Base_URL}${services?.jumper?.image}`
                      : userSix
                  }
                  alt="profile"
                  id="profileImg"
                  className="rounded-full h-39 w-39 pb-0.5"
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="mb-1.5 text-2xl font-semibold text-black dark:text-white">
              {services?.jumper?.firstName} {services?.jumper?.lastName}
            </h3>
            <p className="font-medium flex justify-center">
              {t("jumper")}{" "}
              <span className="flex items-center mx-1" title="your rating">
                ({services?.jumper?.rating.toFixed(1)}{" "}
                <AiTwotoneStar
                  className="inline ml-1 starRateInProfile"
                  fill="gold"
                />
                )
              </span>
            </p>
            <div className="flex justify-center m-4">
              {selectedJumpers.includes(services?.jumper?._id) ? (
                <button
                  className="bgteal px-2 text-stroke p-2 rounded-lg flex justify-center items-center "
                  onClick={undoAddJumpers}
                >
                  {t("Undo")}
                </button>
              ) : (
                <button
                  className="bgteal px-2 text-stroke p-2 rounded-lg flex justify-center items-center "
                  onClick={addJumpers}
                >
                  {t("Accept Jumper")}
                </button>
              )}
            </div>
            <div
              className={`pinfo ${
                data !== "jumper" || userInfo?.isCompany
                  ? "pinfo-full-height"
                  : ""
              } overflow-auto rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark`}
            >
              <div className="p-4 flex justify-center text-base md:text-lg lg:text-xl titleOfSkills">
                {t("'s Personal informations")}
              </div>
              <hr className="px-3 m-3 text-black opacity-40" />
              <div className="flex items-center justify-start gap-1  border-stroke px-4 dark:border-strokedark xsm:flex-row">
                <span className="texttColor">{t("Age")}:</span>
                <span className="font-semibold text-black dark:text-white">
                  {services?.age ? services?.age : "not selected"}
                </span>
              </div>
              <div className="flex items-center justify-start gap-1  border-stroke px-4 dark:border-strokedark xsm:flex-row">
                <span className="texttColor">{t("Gender")}:</span>
                <span className="font-semibold text-black dark:text-white">
                  {services?.gender ? services?.gender : "not selected"}
                </span>
              </div>
              <div className="flex  items-center justify-start gap-1 px-4 xsm:flex-row">
                <p className="texttColor">{t("Nationality")}:</p>
                <p className="font-semibold text-black dark:text-white">
                  {services?.nationality
                    ? services?.nationality
                    : "not selected"}
                </p>
              </div>
              <div className="flex  items-center justify-start gap-1 px-4 xsm:flex-row">
                <p className="texttColor">{t("City")}:</p>
                <p className="font-semibold text-black dark:text-white">
                  {services?.city?.name ? services?.city?.name : "not selected"}
                </p>
              </div>
              {services?.jumper?.about && (
                <>
                  <div className="flex justify-center">
                    <div className="flex items-center quote p-8 justify-center">
                      <p className="flex">
                        <RiDoubleQuotesL />
                        {services?.jumper?.about}
                        <RiDoubleQuotesR />
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {services?.skills?.length ? (
              <>
                <p className="titleOfSkills flex justify-center">
                  {t("'s skills")}
                </p>
                <div className="skillContainer mt-8 flex gap-3 ">
                  {services?.skills.map(
                    (skill) => (
                      // skill.isActive && (
                      <div class="bg-white p-2  leading-none flex justify-evenly items-center dark:border-strokedark dark:bg-[#37404F] text-black dark:text-white rounded-md border border-stroke py-2.5 shadow-1">
                        {skill.name}
                      </div>
                    )
                    // )
                  )}
                </div>
              </>
            ) : (
              <p>{t("this jumper don't have skills")}</p>
            )}
            <hr className="my-4" />
            <p className="titleOfSkills text-center">
              {t("'s service details")}
            </p>
            {services?.description && (
              <>
                <h4 className="font-semibold text-black dark:text-white">
                  {t("About Service")}:
                  <p className="flex justify-center items-center">
                    <RiDoubleQuotesR />
                    <p className="p-2">{services?.description} </p>
                    <RiDoubleQuotesL />
                  </p>
                </h4>

                {/* <div className="mx-auto max-w-180">
                  <h4 className="font-semibold text-black dark:text-white">
                    {t("About Service")}:
                  </h4>
                  <p className="mt-4.5 about">{services?.description} </p>
                </div> */}
              </>
            )}
            {services?.images ? (
              <div className="serviceImages">
                {services?.images?.length > 0 && (
                  <div className="images">
                    {services?.images.map((img) => (
                      <div className="img imgInJP">
                        <img
                          src={`${Base_URL}${img}`}
                          className="imgInJP"
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <p className="w-full">
                {t("this type of service has no images on it")}
              </p>
            )}
            <hr className="my-4" />

            <div>
              <a
                href={`https://api.jumpersksa.online/${cv}`}
                target="_blank"
                className="w-full cursor-pointer rounded-lg border border-meta-3 bgteal p-4 text-white transition hover:bg-opacity-90 mt-10 flex justify-center items-center"
                rel="noreferrer"
              >
                {services?.jumper?.firstName}'s CV{" "}
                <BsPersonVideo2 className="mx-2" fontSize={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JumperProfile;
