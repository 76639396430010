import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ContractModal from "../../components/ContractModal";
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale";

const OfferDetails = ({ socket }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [offer, setOffer] = useState();
  const { userInfo } = useSelector((state) => state.user);
  const [showContractModal, setShowContractModal] = useState(false);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/offers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),

          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setOffer(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [rows, setRows] = useState(
    localStorage.getItem("alertSettings")
      ? JSON.parse(localStorage.getItem("alertSettings"))
      : []
  );
  useEffect(() => {
    // storing input name
    localStorage.setItem("alertSettings", JSON.stringify(rows));
  }, [rows]);

  console.log(offer);
  const startDate = new Date(offer?.order?.startDate);
  const endDate = new Date(offer?.order?.endDate);
  const acceptStatus = () => {
    axios
      .put(
        `https://api.jumpersksa.online/offers/${id}`,
        {
          status: "accepted",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        toast.success(t("offer accepted succfully!"));
        setOffer({ ...offer, status: res?.data?.data?.status });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.err);
      });
  };
  const rejectStatus = () => {
    axios
      .put(
        `https://api.jumpersksa.online/offers/${id}`,
        {
          status: "rejected",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        toast.success(t("offer rejected succfully!"));
        setOffer({ ...offer, status: res?.data?.data?.status });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.err);
      });
  };

  const sendNotoficationOfReject = (client, fname, lname) => {
    const notification = {
      users: [client],
      type: "order offer",
      title_en: "offer refused",
      title_ar: "عرض مرفوض",
      desc_en: "an offer has been refused check it out to more info",
      desc_ar: "تم رفض عرضك يرجى التحقق منه",
      link: offer.order._id,
      createdAt: Date.now(),
    };
    socket.emit("new notfication", notification);
  };

  const changeNameOfGender = (name) => {
    switch (name) {
      case "all":
        return t("All");
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("All");
    }
  };
  const changeNameOfNationalities = (name) => {
    switch (name) {
      case "all":
        return t("All Nationalities");
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("All Nationalities");
    }
  };
  return (
    <>
      <div className="mx-auto max-w-2x">
        <Breadcrumb pageName={t("Offer Details")} />
        {offer?.status === "accepted" && (
          <>
            <div className="flex flex-col flex-wrap justify-center gap-4 mb-5 bg-success bg-opacity-40 p-2">
              <p className="text-2xl font-semibold text-success">
                {t("Offer Acctepted")}
              </p>

              <div className="flex">
                <p className="text-success"> {t("did u want to see")}</p>{" "}
                <Link to={`/contracts/${id}`} className="mx-1">
                  {t("contract")}
                </Link>
              </div>
            </div>
          </>
        )}
        {offer?.status === "rejected" && (
          <>
            <div className="flex flex-col flex-wrap justify-center gap-4 mb-5 bg-danger bg-opacity-40 p-2">
              <p className="text-2xl font-semibold text-danger">
                {t("Offer Rejected")}
              </p>

              {/* <div className="flex">
                <p className="text-danger"> {t("did u want to see")}</p>{" "}
                <Link to={`/contracts/${id}`} className="mx-1">
                  {t("contract")}
                </Link>
              </div> */}
            </div>
          </>
        )}
        {offer?.status === "pending" &&
          new Date(offer?.order?.startDate) < new Date() && (
            <>
              <div className="flex flex-col flex-wrap justify-center gap-4 mb-5 bg-danger bg-opacity-40 p-2">
                <p className="text-2xl font-semibold text-danger">
                  {t("Offer Ended")}
                </p>
              </div>
            </>
          )}{" "}
        {offer?.status === "pending" &&
          new Date(offer?.order?.startDate) > new Date() && (
            <div className="flex flex-wrap justify-center gap-4 mb-5">
              <button
                onClick={() => {
                  setShowContractModal(true);
                }}
                className="inline-flex items-center justify-center rounded-md bg-success py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("Accept")}
              </button>
              {showContractModal && (
                <ContractModal
                  closeModal={() => setShowContractModal(false)}
                  onSubmit={() => {
                    setShowContractModal(false);
                  }}
                  defaultValue
                  id={id}
                  socket={socket}
                />
              )}

              <button
                onClick={() => {
                  rejectStatus(id);
                  sendNotoficationOfReject(
                    offer?.client?._id,
                    userInfo?.firstName,
                    userInfo?.lastName
                  );
                }}
                className="inline-flex items-center justify-center rounded-md bg-danger py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("Reject")}
              </button>
              <Link
                to={`/contracts/${id}`}
                className="inline-flex items-center justify-center rounded-md bg-warning py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("contract")}
              </Link>
            </div>
          )}
        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Offer Info Data")}
                </h3>
              </div>
              <div className="p-7">
                <div class="grid grid-cols-1 divide-y ">
                  <div class="flex flex-row justify-between pb-3">
                    <div>
                      <p>{t("Service Name")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.service?.name}</p>
                    </div>
                  </div>

                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Nationality")}</p>
                    </div>
                    <div>
                      {/* <p>{offer?.order?.orderInfo?.JumpersNationality}</p> */}
                      <p>
                        {changeNameOfNationalities(
                          offer?.order?.orderInfo?.JumpersNationality
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Gender")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfGender(
                          offer?.order?.orderInfo?.JumpersGender
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("City")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.orderInfo?.city?.name}</p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Address")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.orderInfo?.location}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Age Range")}</p>
                    </div>
                    <div>
                      <p>
                        {t("From")} {offer?.order?.orderInfo?.JumpersAgeMin}{" "}
                        {t("To")} {offer?.order?.orderInfo?.JumpersAgeMax}
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Work Description")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.workDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Timing And Costing Data")}
                </h3>
              </div>
              <div className="p-7">
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Hours")}</p>
                  </div>
                  <div>
                    <p>{offer?.order?.timeAndCost.workHours} hours</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Days")}</p>
                  </div>
                  <div>
                    <p>{offer?.order?.timeAndCost.dayNo} Days</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Type")}</p>
                  </div>
                  <div>
                    <p>
                      {offer?.order?.workDays?.length ? (
                        <>{t("Seprated Days")}</>
                      ) : (
                        <>{t("Connected Days")}</>
                      )}
                    </p>
                    <p></p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Pending Period")}</p>
                  </div>
                  <div>
                    <p>{t("24 Hour")}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Start Date")}</p>
                  </div>
                  <div>
                    <p>{startDate.toLocaleString()}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("End Date")}</p>
                  </div>
                  <div>
                    <p>{endDate.toLocaleString()}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Total Cost")}</p>
                  </div>
                  <div>
                    {offer?.order?.timeAndCost?.jumperPrice} {t("SAR")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferDetails;
