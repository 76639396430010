import axios from "axios";
import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUser } from "../../Store/Actions/userActions";
import { useTranslation } from "react-i18next";
const OrganizationInfo = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  console.log(userInfo);
  const [organizationName, setOrganizationName] = useState(
    userInfo?.organization?.name ? userInfo?.organization?.name : ""
  );
  const [vatNumber, setvatNumber] = useState(
    userInfo?.organization?.vatNumber ? userInfo?.organization?.vatNumber : ""
  );
  const [address, setAddress] = useState(
    userInfo?.organization?.address
      ? userInfo?.organization?.address
      : "not selected"
  );
  const [zipcode, setZipcode] = useState(
    userInfo?.organization?.zipCode ? userInfo?.organization?.zipCode : ""
  );
  const [city, setCity] = useState(
    userInfo?.organization?.city._id ? userInfo?.organization?.city._id : ""
  );
  const [commRegister, setcommRegister] = useState(
    userInfo?.organization?.commRegister
      ? userInfo?.organization?.commRegister
      : ""
  );
  const [cities, setCities] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/city`, {
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setCities(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });
  const onSubmit = () => {
    axios
      .put(
        `https://api.jumpersksa.online/users/update_profile`,
        {
          organization: {
            name: organizationName,
            address: address,
            zipCode: zipcode,
            commRegister: commRegister,
            vatNumber: vatNumber,
            city: city,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(setUser(res?.data?.data));
        toast.success(t("Organization informations updated succefully!"));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(t("Error occured please check your informations"));
      });
  };
  return (
    <>
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Organization Info")} />

        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Organization Info")}
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="organizationName"
                      >
                        {t("Organization Name")}
                      </label>
                      <div className="relative">
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="text"
                          name="organizationName"
                          id="organizationName"
                          {...register("organizationName", {
                            required: true,
                            onChange: (e) => {
                              setOrganizationName(e?.target?.value);
                            },
                          })}
                          placeholder={
                            userInfo?.organization?.name
                              ? userInfo?.organization?.name
                              : "not selected"
                          }
                          defaultValue={userInfo?.organization?.name || ""}
                        />
                        {errors.organizationName && (
                          <span className="text-danger">
                            {errors.organizationName.type === "required" &&
                              t("This field is required")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="organizationName"
                      >
                        {t("Vat Number")}
                      </label>
                      <div className="relative">
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="number"
                          name="vatNumber"
                          id="vatNumber"
                          {...register("vatNumber", {
                            required: t("This field is required"),
                            onChange: (e) => {
                              setvatNumber(e.target.value);
                            },
                            minLength: {
                              value: 15,
                              message: t("VAT Number must be 15 digits long"),
                            },
                            maxLength: {
                              value: 15,
                              message: t("VAT Number must be 15 digits long"),
                            },
                          })}
                          placeholder={
                            userInfo?.organization?.vatNumber
                              ? userInfo?.organization?.vatNumber
                              : "not selected"
                          }
                          defaultValue={
                            userInfo?.organization?.vatNumber
                              ? userInfo?.organization?.vatNumber
                              : ""
                          }
                        />
                        {errors.vatNumber && (
                          <span className="text-danger">
                            {errors.vatNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="address"
                      >
                        {t("Address")}
                      </label>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="text"
                        name="address"
                        id="address"
                        {...register("address", {
                          required: true,
                          onChange: (e) => {
                            setAddress(e.target.value);
                          },
                        })}
                        placeholder={
                          userInfo?.organization?.address
                            ? userInfo?.organization?.address
                            : "not selected"
                        }
                        defaultValue={
                          userInfo?.organization?.address
                            ? userInfo?.organization?.address
                            : ""
                        }
                      />
                      {errors?.address && (
                        <span className="text-danger">
                          {errors.address.type === "required" &&
                            t("This field is required")}
                        </span>
                      )}
                    </div>
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="commRegister"
                      >
                        {t("commercial register")}
                      </label>
                      <div className="relative">
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="number"
                          name="commRegister"
                          id="commRegister"
                          {...register("commRegister", {
                            required: t("This field is required"),
                            onChange: (e) => {
                              setcommRegister(e.target.value);
                            },
                            minLength: {
                              value: 10,
                              message: t(
                                "Commercial Register must be 10 digits long"
                              ),
                            },
                            maxLength: {
                              value: 10,
                              message: t(
                                "Commercial Register must be 10 digits long"
                              ),
                            },
                          })}
                          placeholder={
                            userInfo?.organization?.commRegister
                              ? userInfo?.organization?.commRegister
                              : "not selected"
                          }
                          defaultValue={
                            userInfo?.organization?.commRegister
                              ? userInfo?.organization?.commRegister
                              : ""
                          }
                        />
                        {errors.commRegister && (
                          <span className="text-danger">
                            {errors.commRegister.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="zipcode"
                      >
                        {t("Zip Code")}
                      </label>
                      <div className="relative">
                        <input
                          className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          type="number"
                          name="zipcode"
                          id="zipcode"
                          {...register("zipcode", {
                            required: true,
                            onChange: (e) => {
                              setZipcode(e?.target?.value);
                            },
                          })}
                          placeholder={
                            userInfo?.organization?.zipCode
                              ? userInfo?.organization?.zipCode
                              : "not selected"
                          }
                          defaultValue={
                            userInfo?.organization?.zipCode
                              ? userInfo?.organization?.zipCode
                              : ""
                          }
                        />
                        {errors?.zipcode && (
                          <span className="text-danger">
                            {errors?.zipcode?.type === "required" &&
                              t("This field is required")}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="city"
                      >
                        {t("City Of Organization")}
                      </label>
                      <div className="relative">
                        <select
                          {...register("city", {
                            onChange: (e) => {
                              setCity(e.target.value);
                              // let organization = {
                              //   ...userInfo.organization,
                              //   city: e.target.value,
                              // };
                              // dispatch(
                              //   setUser({
                              //     ...userInfo,
                              //     organization,
                              //   })
                              // );
                            },
                          })}
                          name="city"
                          id="city"
                          value={city}
                          className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        >
                          <option disabled value={""}>
                            Select organization City
                          </option>
                          {cities?.map((c, i) => (
                            <option value={c?._id} key={c?._id}>
                              {c?.name}
                            </option>
                          ))}
                        </select>
                        {errors?.city && (
                          <span className="text-danger">
                            {errors?.city?.type === "required" &&
                              t("This field is required")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                    >
                      {t("Save")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationInfo;
