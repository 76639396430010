import React from "react";
import Breadcrumb from "../../src/components/Breadcrumb";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../axiosInst";
import { useEffect } from "react";
import "./style.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ContractModal = ({ closeModal, onSubmit, defaultValue, id, socket }) => {
  const { t } = useTranslation();
  const [offer, setOffer] = useState();
  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/offers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setOffer(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const sendNotoficationOfAccept = (client, fname, lname) => {
    const notification = {
      users: [client],
      type: "order offer",
      title_en: "offer acepted",
      desc_en: "an offer has been accepted check it out to more info",
      title_ar: "عرض مقبول",
      desc_ar: "تم قبول عرضك يرجى التحقق منه",
      link: offer.order._id,
      createdAt: Date.now(),
    };
    socket.emit("new notfication", notification);
  };
  const acceptStatus = () => {
    axios
      .put(
        `https://api.jumpersksa.online/offers/${id}`,
        {
          status: "accepted",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success(t("offer accepted succfully!"));
        setTimeout(() => {
          navigate("/orders");
        }, 3000);
        setOffer({ ...offer, status: res?.data?.data?.status });
        sendNotoficationOfAccept(
          offer?.client?._id,
          userInfo?.firstName,
          userInfo?.lastName
        );
        socket.emit("update order", [offer?.client?._id]);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <div
      className="modal-container-two"
      onClick={(e) => {
        if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div className="relative w-full h-fit modal rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto ">
        <div className=" border-stroke py-4 px-7">
          <div className="w-full flex justify-end">
            <strong
              className="text-xl align-center cursor-pointer "
              onClick={closeModal}
            >
              &times;
            </strong>
          </div>{" "}
          <p className="text-2xl m-3">{t("Contract aprovment")}</p>
          <div className="mb-5.5 w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary">
            <p>
              {t("By clicking 'Agree' then you agree on the")}{" "}
              <Link
                target="_blank"
                className="text-primary cursor-pointer"
                to={`/contracts/${id}`}
              >
                {t("Contract Formattt")}
              </Link>{" "}
              {t("the Jumpers Platform has submitted")}
            </p>
          </div>
          <div className="flex justify-between items-end gap-4.5 bottom-4 right-4">
            <button
              className="flex justify-center rounded bg-success py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={() => {
                acceptStatus(id);
                closeModal();
              }}
            >
              {t("Agree")}
            </button>
            <button
              className="flex justify-center rounded bg-danger py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={closeModal}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractModal;
