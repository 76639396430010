import {createStore,combineReducers,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { orderJumpersReducer } from './Reducers/orderJumpersReducer';
import { userReducer } from './Reducers/userReducer';
import { orderReducer } from './Reducers/orderReducer';


const reducer = combineReducers({
    orderJumpers: orderJumpersReducer,
    user:userReducer,
    order:orderReducer
});


const initState = {
    orderJumpers:[],
    user:{userInfo:null,loader:true},
    order:{}
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;