import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OrderingSteps = ({ step1, step2, step3, step4 }) => {
  const { t } = useTranslation();
  return (
    <ul className="flex justify-content-center mb-4">
      <li class="mr-6">
        {step1 ? (
          <Link
            to="/orderinfo"
            className="text-blue-500 hover:text-blue-800 underline "
          >
            {t("Order Information")}
          </Link>
        ) : (
          <Link disabled>{t("Ordering Information")}</Link>
        )}
      </li>
      <li class="mr-6">
        {step2 ? (
          <Link
            to="/ordertimingandcosts"
            className="text-blue-500 hover:text-blue-800 underline "
          >
            {t("Timing And Costs")}
          </Link>
        ) : (
          <Link disabled>{t("Timing And Costs")}</Link>
        )}
      </li>{" "}
      <li class="mr-6">
        {step3 ? (
          <Link
            to="/selectjumbers"
            className="text-blue-500 hover:text-blue-800 underline "
          >
            {t("Select Jumbers")}
          </Link>
        ) : (
          <Link disabled>{t("Select Jumbers")}</Link>
        )}
      </li>
      <li class="mr-6">
        {step4 ? (
          <Link
            to="/orderreviewandpublication"
            className="text-blue-500 hover:text-blue-800 underline "
          >
            {t("Review And Publication")}
          </Link>
        ) : (
          <Link disabled>{t("Review And Publication")}</Link>
        )}
      </li>
    </ul>
  );
};

export default OrderingSteps;
