import { Link } from "react-router-dom";
import axios from "axios";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { AiFillEyeInvisible, AiOutlinePhone } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import signUpImg from "../../images/authPics/register.png";
import logo from "../../images/logo/logo (1).png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { FaEye } from "react-icons/fa";
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "sa",
    dir: "rtl",
  },
];
const SignUpClient = () => {
  const [t, i18n] = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const showPage2 = () => setCurrentPage(2);
  const showPage3 = () => setCurrentPage(3);
  const firstName = useRef();
  const lastName = useRef();
  const [emailVal, setEmailVal] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgree, setIsAgree] = useState(true);
  console.log(isAgree);
  const email = useRef();
  // const phoneNumber = useRef();
  const password = useRef();
  const confirmPassword = useRef();
  const verifyEmailCode = useRef();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const createAccount = (e) => {
    console.log(isCompany);
    setIsButtonDisabled(true);
    if (!isAgree) {
      e.preventDefault();
      toast.error(t("Please agree on terms and conditions"));
      setIsButtonDisabled(false);
      return;
    }
    axios
      .post(
        `https://api.jumpersksa.online/users/register`,
        {
          firstName: firstName?.current?.value,
          lastName: lastName?.current?.value,
          email: email?.current?.value,
          phoneNumber: `966${phoneNumber}`,
          password: password?.current?.value,
          confirmPassword: confirmPassword?.current?.value,
          role: "client",
          isCompany: isCompany,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        toast.success(t("please check your phone to activate your account"));
        setIsButtonDisabled(false);

        showPage2();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.errors[0]?.msg || err?.response?.data?.err
        );
        // toast.error("error happend");
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
  };

  const verifyEmail = () => {
    console.log(phoneNumber);
    setShowRESend(true);
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/verify_phone`,
        {
          phoneNumber: `966${phoneNumber}`,
          verifyEmailCode: verifyEmailCode?.current?.value,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
          },
        }
      )
      .then((res) => {
        setIsButtonDisabled(false);
        toast.success(t("Phone is verified succefully please signIn again"));
        showPage3();
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        toast.error(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
  };

  const [showReSend, setShowRESend] = useState(false);
  const [isActive, setIsActive] = useState(false);
  let interval;
  const [counter, setCounter] = useState(0);
  const startCounter = () => {
    interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter > 0) {
          console.log(prevCounter);
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          setIsActive(true);
          return 0;
        }
      });
    }, 1000);
  };
  useEffect(() => {
    if (counter === 60) {
      startCounter();
    }
  }, [counter]);
  const reSendOTP = () => {
    setCounter(60);
    axios
      .post(
        `https://api.jumpersksa.online/users/resend_verify_phone_code`,
        {
          phoneNumber: `966${phoneNumber}`,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsButtonDisabled(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        toast.error(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
    setIsActive(false);
  };
  const [showEye, setShowEye] = useState(false);
  const [showEyeOfConfirm, setShowEyeOfConfirm] = useState(false);
  const [typeOfPass, setTypeOfPass] = useState("password");
  const showPassword = () => {
    setShowEye(true);
    if (typeOfPass === "password") {
      setTypeOfPass("text");
    } else setTypeOfPass("password");
  };
  const [typeOfConfirmPass, setTypeOfConfirmPass] = useState("password");
  const showConfirmPassword = () => {
    setShowEyeOfConfirm(true);
    if (typeOfConfirmPass === "password") {
      setTypeOfConfirmPass("text");
    } else setTypeOfConfirmPass("password");
  };
  const [isCompany, setIsCompany] = useState();

  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const currentLang = Cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLang);
  // const { t, i18n } = useTranslation();
  const handleLanguageChange = (languageCode) => {
    // window.location.reload();
    i18n.changeLanguage(languageCode);
    // Use i18n to change the language
  };
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
  }, [currentLanguage.dir]);

  return (
    <>
      <div className="rounded-sm border min-h-screen border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-wrap items-center">
          <div className=" w-full xl:block xl:w-1/2">
            <Link
              active
              className="focus:underline flex justify-center items-center text-dark dark:text-white bg-transparent rounded "
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-globe2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                </svg>
              </i>
              {/* <span id="change-lang">English</span> */}
              <select
                name="lang"
                id="lang"
                className="bg-transparent text-dark"
                value={currentLang}
                onChange={(e) => handleLanguageChange(e.target.value)}
              >
                {languages?.map(({ code, name, country_code }) => (
                  <option
                    key={country_code}
                    value={code}
                    id="optLang"
                    className="text-dark bg-black"
                  >
                    {name}
                  </option>
                ))}
              </select>
            </Link>
            <div className="text-center">
              <div id="logoAndText">
                <Link className="inline-block" to="/">
                  <img
                    className="hidden dark:block"
                    src={logo}
                    alt="Logo"
                    id="logo"
                  />
                  <img
                    className="dark:hidden"
                    src={logo}
                    alt="Logo"
                    id="logo"
                  />
                </Link>

                <p
                  className="2xl:px-3 text-black dark:text-white"
                  id="textinSignIn"
                >
                  جمبرز منصة تفاعلية ووسيط هادف
                </p>
              </div>

              <span className="inline-block">
                <img
                  src={signUpImg ? signUpImg : "not avaliable"}
                  alt="not avaliable"
                />
              </span>
            </div>
          </div>

          <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
            {currentPage === 1 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                <span className="mb-1.5 block font-medium text-black dark:text-white">
                  {t("Start for free")}
                </span>
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Sign Up to Jumpers (client register)")}
                </h2>

                <form>
                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("First Name")}
                    </label>
                    <div className="relative">
                      <input
                        ref={firstName}
                        required
                        minLength={3}
                        type="text"
                        placeholder={t("Enter your first name")}
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />

                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M11.0008 9.52185C13.5445 9.52185 15.607 7.5281 15.607 5.0531C15.607 2.5781 13.5445 0.584351 11.0008 0.584351C8.45703 0.584351 6.39453 2.5781 6.39453 5.0531C6.39453 7.5281 8.45703 9.52185 11.0008 9.52185ZM11.0008 2.1656C12.6852 2.1656 14.0602 3.47185 14.0602 5.08748C14.0602 6.7031 12.6852 8.00935 11.0008 8.00935C9.31641 8.00935 7.94141 6.7031 7.94141 5.08748C7.94141 3.47185 9.31641 2.1656 11.0008 2.1656Z"
                              fill=""
                            />
                            <path
                              d="M13.2352 11.0687H8.76641C5.08828 11.0687 2.09766 14.0937 2.09766 17.7719V20.625C2.09766 21.0375 2.44141 21.4156 2.88828 21.4156C3.33516 21.4156 3.67891 21.0719 3.67891 20.625V17.7719C3.67891 14.9531 5.98203 12.6156 8.83516 12.6156H13.2695C16.0883 12.6156 18.4258 14.9187 18.4258 17.7719V20.625C18.4258 21.0375 18.7695 21.4156 19.2164 21.4156C19.6633 21.4156 20.007 21.0719 20.007 20.625V17.7719C19.9039 14.0937 16.9133 11.0687 13.2352 11.0687Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Last Name")}
                    </label>
                    <div className="relative">
                      <input
                        ref={lastName}
                        required
                        minLength={3}
                        type="text"
                        placeholder={t("Enter your last name")}
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />

                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M11.0008 9.52185C13.5445 9.52185 15.607 7.5281 15.607 5.0531C15.607 2.5781 13.5445 0.584351 11.0008 0.584351C8.45703 0.584351 6.39453 2.5781 6.39453 5.0531C6.39453 7.5281 8.45703 9.52185 11.0008 9.52185ZM11.0008 2.1656C12.6852 2.1656 14.0602 3.47185 14.0602 5.08748C14.0602 6.7031 12.6852 8.00935 11.0008 8.00935C9.31641 8.00935 7.94141 6.7031 7.94141 5.08748C7.94141 3.47185 9.31641 2.1656 11.0008 2.1656Z"
                              fill=""
                            />
                            <path
                              d="M13.2352 11.0687H8.76641C5.08828 11.0687 2.09766 14.0937 2.09766 17.7719V20.625C2.09766 21.0375 2.44141 21.4156 2.88828 21.4156C3.33516 21.4156 3.67891 21.0719 3.67891 20.625V17.7719C3.67891 14.9531 5.98203 12.6156 8.83516 12.6156H13.2695C16.0883 12.6156 18.4258 14.9187 18.4258 17.7719V20.625C18.4258 21.0375 18.7695 21.4156 19.2164 21.4156C19.6633 21.4156 20.007 21.0719 20.007 20.625V17.7719C19.9039 14.0937 16.9133 11.0687 13.2352 11.0687Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Email")}
                    </label>
                    <div className="relative">
                      <input
                        ref={email}
                        type="email"
                        required
                        placeholder={t("Enter your email")}
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />

                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("phone number")}
                    </label>
                    <div className="relative" dir="ltr">
                      <div className="w-full flex">
                        <span className="text-gray-500 spanPhone dark:text-gray-400 px-2.5 border-stroke bg-transparent text-black dark:text-white outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                          +966
                        </span>

                        <input
                          className={
                            i18n.language === "ar"
                              ? "w-full inputPhone text-black dark:text-white border border-stroke bg-transparent py-4 pr-6 pl-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                              : "w-full inputPhone text-black dark:text-white border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          }
                          type="number"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={phoneNumber}
                          {...register("phoneNumber", {
                            required: true,
                            pattern: /^(5|0|3|6|4|9|1|8|7)([0-9]{8})$/,
                            onChange: (e) => {
                              setPhoneNumber(e.target?.value);
                            },
                          })}
                          placeholder="56 --- ----"
                        />
                      </div>
                      {errors.phoneNumber && (
                        <span className="text-danger">
                          {errors.phoneNumber.type === "required" &&
                            t("This field is required")}
                          {errors.phoneNumber.type === "pattern" &&
                            t("Invalid phone number")}
                        </span>
                      )}
                      <span
                        className={
                          "absolute right-4 top-4 text-black dark:text-white"
                        }
                      >
                        <AiOutlinePhone fontSize={25} />
                      </span>
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Are you a company or an individual?")}
                    </label>
                    <div className="flex space-x-4">
                      <div
                        className={`radioDesign m-2  ${
                          isCompany ? "radioSelected" : ""
                        }`}
                        htmlFor="company"
                      >
                        <input
                          type="radio"
                          name="isCompany"
                          className="cursor-pointer"
                          value={true}
                          id="company"
                          onClick={() => {
                            setIsCompany(true);
                          }}
                        />
                        <label
                          htmlFor="company"
                          className="mx-3 cursor-pointer"
                        >
                          {t("Company")}
                        </label>
                      </div>
                      <div
                        className={`radioDesign m-2 ${
                          !isCompany ? "radioSelected" : ""
                        }`}
                        htmlFor="individual"
                      >
                        <input
                          type="radio"
                          name="isCompany"
                          className="cursor-pointer "
                          value={false}
                          id="individual"
                          onClick={() => {
                            setIsCompany(false);
                          }}
                        />
                        <label
                          htmlFor="individual"
                          className="mx-3 cursor-pointer"
                        >
                          {t("Individual")}
                        </label>
                      </div>
                    </div>
                    {errors.isCompany && (
                      <span className="text-danger">
                        {errors.isCompany.type === "required" &&
                          "This field is required"}
                      </span>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Password")}
                    </label>
                    <div className="relative">
                      <input
                        ref={password}
                        required
                        minLength={8}
                        type={typeOfPass}
                        placeholder={t("Enter your password")}
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      {!showEye ? (
                        <AiFillEyeInvisible
                          className={`text-black dark:text-white
                        ${
                          i18n.language === "ar"
                            ? "absolute left-5 top-4.5 mx-2"
                            : "absolute right-9 top-4.5 mx-2"
                        }`}
                          id="anthorIcon"
                          onClick={showPassword}
                          size={20}
                        />
                      ) : (
                        <FaEye
                          size={20}
                          className={`text-black dark:text-white
                            ${
                              i18n.language === "ar"
                                ? "absolute left-5 top-4.5 mx-2"
                                : "absolute right-9 top-4.5 mx-2"
                            }`}
                          id="anthorIcon"
                          onClick={() => {
                            setShowEye(false);
                            setTypeOfPass("password");
                          }}
                        />
                      )}
                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Re-type Password")}
                    </label>
                    <div className="relative">
                      <input
                        ref={confirmPassword}
                        type={typeOfConfirmPass}
                        required
                        minLength={8}
                        placeholder={t("Re-type Password")}
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      {!showEyeOfConfirm ? (
                        <AiFillEyeInvisible
                          className={`text-black dark:text-white
                        ${
                          i18n.language === "ar"
                            ? "absolute left-5 top-4.5 mx-2"
                            : "absolute right-9 top-4.5 mx-2"
                        }`}
                          id="anthorIcon"
                          onClick={showConfirmPassword}
                          size={20}
                        />
                      ) : (
                        <FaEye
                          size={20}
                          className={`text-black dark:text-white
                            ${
                              i18n.language === "ar"
                                ? "absolute left-5 top-4.5 mx-2"
                                : "absolute right-9 top-4.5 mx-2"
                            }`}
                          id="anthorIcon"
                          onClick={() => {
                            setShowEyeOfConfirm(false);
                            setTypeOfConfirmPass("password");
                          }}
                        />
                      )}
                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="mb-6 flex items-center">
                    <label htmlFor="privacy">
                      <span className="text-black dark:text-white">
                        {t("Agree on")}{" "}
                      </span>
                      <Link to="/auth/privacypolicy" className="linkCC">
                        {t("Terms of Use and privacy policy")}
                      </Link>
                    </label>
                    <input
                      type="checkbox"
                      checked={isAgree}
                      onChange={(e) => setIsAgree(e.target.checked)}
                      id="privacy"
                      className="mx-3 mt-1 "
                    />
                  </div>

                  <div className="mb-5">
                    <input
                      disabled={isButtonDisabled}
                      onClick={(e) => {
                        createAccount(e);
                        setEmailVal(phoneNumber?.current?.value);
                        setCounter(60);
                      }}
                      type="submit"
                      required
                      id="submitBtn"
                      value={t("Create account")}
                      className="w-full cursor-pointer rounded-lg border border-primary bgteal p-4 text-white transition hover:bg-opacity-90"
                    />
                  </div>

                  <div className="mt-6 text-center">
                    <p className="text-black dark:text-white">
                      {t("Already have an account?")}
                      <Link to="/auth/signin" className="linkCC m-1">
                        {t("Sign in")}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            )}
            {currentPage === 2 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Verify your Phone Number")}
                </h2>

                <form>
                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Enter OTP")}
                    </label>
                    <div className="relative">
                      <input
                        ref={verifyEmailCode}
                        type="number"
                        placeholder="*****"
                        required
                        className="w-full text-black dark:text-white rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-5 text-black dark:text-white">
                    <input
                      disabled={isButtonDisabled}
                      onClick={() => {
                        verifyEmail();
                        startCounter();
                      }}
                      type="submit"
                      value={t("Verify Account")}
                      className="w-full cursor-pointer rounded-lg border border-primary bgteal p-4 text-white transition hover:bg-opacity-90"
                    />
                  </div>

                  <>
                    <div className="reSendOTP text-black dark:text-white">
                      {t("if you did not get any OTP try")}
                      <button
                        onClick={reSendOTP}
                        disabled={!isActive}
                        className="sa cursor-pointer"
                      >
                        {t("Re-Send OTP")}
                      </button>{" "}
                      {t("after")}{" "}
                      <span className="counter text-black dark:text-white">
                        {counter}
                      </span>{" "}
                    </div>
                  </>
                </form>
              </div>
            )}
            {currentPage === 3 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                {/* <span className="mb-1.5 block font-medium">Start for free</span> */}
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  <div className="mt-6 text-center">
                    <p>
                      {t("please sign in again ...")}
                      <Link to="/auth/signin" className="text-primary">
                        {t("Sign in")}
                      </Link>
                    </p>
                  </div>
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpClient;
