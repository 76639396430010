import { ADD_JUMPER, RMV_JUMPER, REST_JUMPERS } from "../Constants/orderJumpersConstants";


export const AddJumper = (value)=>{
    return{
        type: ADD_JUMPER,
        payload:value
    }
}


export const rmvJumper = (value)=>{
    return{
        type: RMV_JUMPER,
        payload:value
    }
}


export const restJumpers = (value)=>{
    return{
        type:REST_JUMPERS,
        payload:value
    }
}
