import Breadcrumb from "../../components/Breadcrumb";
import fireToast from "../../hooks/fireToast";
import { useState, useEffect } from "react";
import OrderingSteps from "../../components/OrderingSteps";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import { axiosInstance } from "../../axiosInst";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setOrder } from "../../Store/Actions/orderActions";
import { useTranslation } from "react-i18next";
import { FaCircleQuestion } from "react-icons/fa6";

const ClientOrderTimingAndCosts = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order);

  const [time, setTime] = useState(null);
  const today = new Date();
  today.setTime(today.getTime() + 60 * 60000);
  const [value, setValue] = useState(
    order?.startDate ? order.startDate : new Date(today)
  );
  const tomorrow = new Date();
  const [workType, setWorkType] = useState(
    order?.timeAndCost?.workType ? order.timeAndCost.workType : ""
  );
  const [workingHours, setWorkingHours] = useState([]);
  const [workingHour, setWorkingHour] = useState(
    order?.workingHour ? order.workingHour : ""
  );
  const [wHours, setWHours] = useState(1);
  const [wHoursM, setWHoursM] = useState();
  const [workingDays, setWorkingDays] = useState(
    order?.timeAndCost?.dayNo ? order.timeAndCost.dayNo : 1
  );
  const [costPerHour, setCostPerHour] = useState(
    order?.timeAndCost?.packagePrice
  );
  const [error, setError] = useState("");
  const [totalCost, setTotalCost] = useState();
  const [workDescription, setWorkDescription] = useState(order?.workDesc);

  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setTime(tomorrow.getTime() + 2 * 60 * 60000);
  const [endDate, setEndDate] = useState();
  const [multiDaysDatePicker, setMultiDaysDatePicker] = useState(
    order?.workDays?.length ? order.workDays : [today]
  );

  const [showSeperateOption, setShowSeperateOption] = useState(
    order?.workDays?.length ? true : false
  );
  const [showConnectedOption, setShowConnectedOption] = useState(false);
  const [changeWorkType, setChangeWorkType] = useState(null);

  const navigate = useNavigate();
  let sss = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(value);

  // console.log(sss);

  const handleSaveTimingAndCosting = (e) => {
    e.preventDefault();
    let obj = {
      timeAndCost: {
        workHours: wHours,
        dayNo: workingDays,
        packagePrice: costPerHour,
        jumperPrice: totalCost,
        workType,
      },
      workDays: showSeperateOption ? multiDaysDatePicker : [],
      startDate: value,
      endDate,
      workDesc: workDescription,
      workingHour,
    };
    console.log(obj);
    dispatch(setOrder(obj));
    toast.success(t("Time and Cost Saved Successfully"));
    navigate("/selectjumbers");
  };

  const handleShowDateTimePicker = (e) => {
    setWorkType(e.target.value);
    if (e.target.value === "Seperated Days") {
      setShowSeperateOption(true);
    } else {
      setShowSeperateOption(false);
    }

    if (e.target.value === "Connected Days") {
      setShowConnectedOption(true);
    } else {
      setShowConnectedOption(false);
    }

    setChangeWorkType(e.target.value);
  };
  const handleCostPerHourChange = (e) => {
    const newValue = parseFloat(e.target.value);

    if (newValue < wHoursM) {
      setError(
        `${t("The smallest amount of money for this package is")} : ${wHoursM}`
      );
    } else {
      setError("");
    }

    setCostPerHour(newValue);
  };

  const calcEndDate = () => {
    let d;
    console.log(showSeperateOption, multiDaysDatePicker.length);
    if (showSeperateOption && multiDaysDatePicker.length) {
      d = new Date(multiDaysDatePicker[multiDaysDatePicker.length - 1]);
    } else {
      console.log("ffffffffffdddd", value);
      d = new Date(value);
      d.setDate(d.getDate() + workingDays - 1);
    }

    d.setTime(d.getTime() + wHours * 60 * 60000);
    setEndDate(d);
  };

  const fetchWHours = async () => {
    const result = await axiosInstance.get("/work_hours?isActive=true");
    console.log(result.data.data);
    setWorkingHours(result.data.data);
  };

  useEffect(() => {
    fetchWHours();
  }, []);

  useEffect(() => {
    calcEndDate();
  }, [value, wHours, workingDays]);

  useEffect(() => {
    setTotalCost(costPerHour * workingDays);
  }, [costPerHour, workingDays]);

  useEffect(() => {
    if (changeWorkType) {
      setValue(new Date(today));
      setMultiDaysDatePicker([new Date(today)]);
      setWorkingDays(1);
    }
  }, [changeWorkType]);
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <OrderingSteps step1 step2 />
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Time and Cost for Order")} />

        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Time and Cost for Order")}
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSaveTimingAndCosting}>
                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="workingHours"
                      >
                        {t("Working Hours")}
                      </label>
                      <div className="relative">
                        <select
                          value={workingHour}
                          onChange={(e) => {
                            let wh = workingHours.find(
                              (w) => w._id === e.target.value
                            );
                            setWHours(wh.workHours);
                            setWHoursM(wh.minPrice);
                            setWorkingHour(e.target.value);
                          }}
                          required
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          <option disabled value="">
                            {t("Working Hours")}
                          </option>
                          {workingHours?.map((w) => {
                            return (
                              <option value={w._id} key={w._id}>
                                {w.workHours}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="workingdays"
                      >
                        {t("Working Days")}
                      </label>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="number"
                        name="workingdays"
                        id="workingdays"
                        min="1"
                        required
                        value={workingDays}
                        readOnly={showSeperateOption}
                        onChange={(e) =>
                          setWorkingDays(parseInt(e.target.value))
                        }
                        placeholder="Working Days"
                      />
                    </div>
                  </div>
                  <div className="mb-5.5">
                    <div>
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Work Type")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <select
                          value={workType}
                          onChange={handleShowDateTimePicker}
                          required
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          <option value="Connected Days">
                            {t("Connected Days")}
                          </option>
                          <option value="Seperated Days">
                            {t("Separated Days")}
                          </option>
                        </select>
                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill="#637381"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  {showSeperateOption && (
                    <div className="mb-5.5 ">
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Select Multiple Days")}
                      </label>
                      <div className="relative">
                        <DatePicker
                          containerStyle={{
                            width: "100%",
                          }}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "50px",
                            backgroundColor: "aliceblue",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "3px 10px",
                          }}
                          plugins={[<TimePicker position="bottom" />]}
                          format="DD/MM/YYYY HH:mm:ss"
                          minDate={today}
                          required
                          multiple
                          value={multiDaysDatePicker}
                          onChange={(e) => {
                            if (e.length) {
                              const arr = e.map((d) => d.toDate());
                              if (arr.length > 1)
                                arr.sort((a, b) => a.getTime() - b.getTime());
                              setMultiDaysDatePicker(arr);
                              setValue(arr[0]);
                              setWorkingDays(arr.length);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Start Date")}
                      </label>
                      <div className="relative">
                        <DatePicker
                          id="startDate"
                          containerStyle={{
                            width: "100%",
                          }}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "50px",
                            backgroundColor: "aliceblue",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "3px 10px",
                          }}
                          plugins={[<TimePicker position="bottom" />]}
                          minDate={today}
                          required
                          disabled={showSeperateOption}
                          format="DD/MM/YYYY HH:mm:ss"
                          value={value}
                          onChange={(e) => {
                            if (today < e.toDate()) {
                              setValue(e.toDate());
                              setError("");
                            } else {
                              setError(
                                `you should select date grater than ${today.toLocaleString()}`
                              );
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label className="mb-3 block text-black dark:text-white">
                        {t("End Date")}
                      </label>
                      <div className="relative">
                        <DatePicker
                          id="endDate"
                          name="endDate"
                          containerStyle={{
                            width: "100%",
                          }}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            height: "50px",
                            backgroundColor: "aliceblue",
                            borderRadius: "8px",
                            fontSize: "14px",
                            padding: "3px 10px",
                          }}
                          disabled
                          format="DD/MM/YYYY HH:mm:ss"
                          value={endDate}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2 trans">
                      {showDetails && (
                        <div
                          className="flex justify-end relative trans"
                          // onMouseMove={() => setShowDetails(true)}
                          // onMouseLeave={() => setShowDetails(false)}
                        >
                          <div
                            onClick={() => {
                              setShowDetails(false);
                            }}
                            className="bg-black relative bg-opacity-30 trans text-white duration-300 ease-in-out dark:bg-body transition-all w-fit rounded-sm p-2 cursor-pointer"
                          >
                            {t(
                              "the amount of money you wish to pay for the jumper per day"
                            )}
                            <span
                              onClick={() => {
                                setShowDetails(false);
                              }}
                              className="absolute -right-2 -top-1 rounded-full bg-danger bg-opacity-50 text-white font-bold text-xs w-4 text-center"
                            >
                              X
                            </span>
                          </div>
                        </div>
                      )}
                      <label
                        className="mb-3 text-sm font-medium trans text-black dark:text-white flex justify-between items-center"
                        htmlFor="suggestedcost"
                      >
                        {t("Suggested cost per hour")}
                        <FaCircleQuestion
                          className="cursor-pointer trans transition-all duration-300 ease-in-out"
                          onClick={() => setShowDetails(true)}
                        />
                      </label>

                      <input
                        className={`w-full rounded border ${
                          error ? "border-red-500" : "border-stroke"
                        } bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary`}
                        type="number"
                        name="suggestedcost"
                        id="suggestedcost"
                        min={wHoursM}
                        value={costPerHour}
                        required
                        readOnly={wHoursM ? false : true}
                        onChange={handleCostPerHourChange}
                      />
                      <span style={{ color: "red" }}>{error}</span>
                      {/* {error && <p className="text-red-500">{error}</p>} */}
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="totalcost"
                      >
                        {t("Total CostClient")}
                      </label>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="number"
                        name="totalcost"
                        id="totalcost"
                        required
                        value={totalCost}
                        readOnly
                        lang="en"
                      />
                    </div>
                  </div>
                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="workdescription"
                    >
                      {t("Work Description")}
                    </label>
                    <div className="relative">
                      <span className="absolute left-4.5 top-4">
                        <svg
                          className="fill-current"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8" clipPath="url(#clip0_88_10224)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                              fill=""
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                              fill=""
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_88_10224">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <textarea
                        className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="workdescription"
                        id="workdescription"
                        rows={6}
                        value={workDescription}
                        minLength="11"
                        required
                        placeholder={t("Write your work description here")}
                        onChange={(e) => {
                          setWorkDescription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                      onClick={fireToast}
                    >
                      {t("Save")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientOrderTimingAndCosts;
