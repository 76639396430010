import Breadcrumb from "../components/Breadcrumb";
import { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const AddNewSkill = () => {
  const [skillss, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const handleSkillsChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions);
  };

  const skillOptions = skillss.map((skill) => ({
    value: skill._id,
    label: skill.name,
  }));

  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/skills`, {
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setSkills(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    console.log(selectedSkills);
    e.preventDefault();
    const skillIds = selectedSkills.map((skill) => skill.value);
    console.log(skillIds);

    axios
      .put(
        `https://api.jumpersksa.online/users/update_profile`,
        {
          skills: skillIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success("skills added successfully !", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className="mx-auto max-w-270">
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Add New Skill
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSubmit}>
                  <div className="mb-5.5">
                    <div>
                      <label className="mb-3 block text-black dark:text-white">
                        Select skill name
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <Select
                          isMulti
                          name="colors"
                          value={selectedSkills}
                          onChange={handleSkillsChange}
                          options={skillOptions}
                          className="w-full"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                    >
                      Save Skills
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddNewSkill;
