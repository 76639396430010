import { ADD_JUMPER, RMV_JUMPER, REST_JUMPERS } from "../Constants/orderJumpersConstants";

export const orderJumpersReducer = (state = [],action)=>{
    switch (action.type) {
        case ADD_JUMPER:
            return [action.payload, ...state];
        case RMV_JUMPER:
            const filtered = state.filter(item => item !== action.payload);
            return filtered;
        case REST_JUMPERS:
            return [];
        default:
            return state;
    }
}