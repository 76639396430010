import React from "react";
import "./Complaint.css";
import Loader from "../../common/Loader";
import Breadcrumb from "../../components/Breadcrumb";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ComplaintDetails = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [complian, setcomplian] = useState();
  const [date, setDate] = useState();
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/claims/user_claim/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res);
        setcomplian(res?.data?.data);
        setDate(res?.data?.data?.createdAt);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);
  const getComplaintStatus = (status) => {
    switch (status) {
      case "pending":
        return t("Pending");
      case "accepted":
        return t("Accepted");
      case "rejected":
        return t("Rejected");
      default:
        return "pending";
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumb pageName="Complaint Details" />
      <div className="complainDetailsContainer shadow-lg">
        <div className="line">
          <div className="titleOfComplaint"> {t("Complaint about")} </div>
          <div className="detailsOfComplaint">
            <div>
              {complian?.jumper?.firstName} {complian?.jumper?.lastName}
            </div>
          </div>
        </div>
        <div className="line">
          <div className="titleOfComplaint"> {t("at the date of")}</div>
          <div className="detailsOfComplaint">
            <div>
              {complian?.createdAt
                ? new Date(complian.createdAt).toLocaleString()
                : ""}
            </div>
          </div>
        </div>
        <div className="line">
          <div className="titleOfComplaint">{t("your complaint details ")}</div>
          <div className="detailsOfComplaint">
            <div>{complian?.desc}</div>
          </div>
        </div>
        <div className="line">
          <div className="titleOfComplaint">{t("complaint status")}</div>
          <div className="detailsOfComplaint">
            <div
              className={`inline-flex rounded-full  bg-opacity-10 py-1 px-3 text-sm font-medium  
                   ${
                     complian?.status === "accepted"
                       ? "bg-success text-success"
                       : complian.status === "rejected"
                       ? "bg-danger text-danger"
                       : "bg-warning text-warning"
                   }
                  `}
            >
              {getComplaintStatus(complian?.status)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintDetails;
