import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
const Complain = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [jumpers, setJumpers] = useState([]);
  const [complainedJumper, setJumper] = useState();
  const navigate = useNavigate();
  const [complainedJumpers, setComplainedJumpers] = useState([]);
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [workDays, setworkDays] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const complaint = useRef();
  const date = useRef();
  const jumperC = useRef();
  // ... (previous code)

  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/orders/user_order/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setJumpers(res?.data?.data?.jumpers);
        setstartDate(res?.data?.data?.startDate);
        setendDate(res?.data?.data?.endDate);
        setworkDays(res?.data?.data?.workDays);

        // Check if workDays exist in the response
        if (res?.data?.data?.workDays && res?.data?.data?.workDays.length > 0) {
          // Format workDays to "DD/MM/YYYY"
          const formattedWorkDays = res?.data?.data?.workDays.map((day) =>
            moment(day).format("MMMM D, YYYY")
          );
          setDateRange(formattedWorkDays);
        } else {
          // If workDays do not exist, generate continuous days between startDate and endDate
          const daysArray = [];
          let currentDate = moment(startDate);
          const endDateMoment = moment(endDate);

          while (currentDate.isSameOrBefore(endDateMoment)) {
            // Format continuous days to "DD/MM/YYYY"
            daysArray.push(currentDate.format("MMMM D, YYYY"));
            currentDate.add(1, "day");
          }

          setDateRange(daysArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, startDate, endDate]);

  const [selectedJumpers, setSelectedJumpers] = useState([]);
  const complainJumpers = selectedJumpers?.map((jumper) => {
    return jumper?.value;
  });
  const selectJumpers = (Jumpers) => {
    setSelectedJumpers(Jumpers);
  };

  console.log(complainJumpers);
  const [dateComplain, setdateComplain] = useState([]);
  const selectDate = (date) => {
    setdateComplain(date);
  };
  const complainDates = dateComplain?.map((date) => {
    return date.value;
  });
  const submitClaim = (e) => {
    e.preventDefault();
    if (complainedJumpers.includes(complainedJumper)) {
      toast.error(t("You have already complained about this jumper."));
      return;
    }
    setComplainedJumpers([...complainedJumpers, complainedJumper]);
    axios
      .post(
        `https://api.jumpersksa.online/claims`,
        {
          jumper: complainedJumper,
          order: id,
          title: "claim",
          desc: complaint?.current?.value,
          date: complainDates,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success(
          t("The complaint has been successfully sent and is awaiting review.")
        );
        setTimeout(() => {
          navigate("/complaints");
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log(complainedJumper);

  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t(
                    "Your complaint will be reviewed, and we will contact you soon."
                  )}
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={submitClaim}>
                  <div class="grid grid-cols-1 divide-y ">
                    <div className="mb-5.5">
                      <div>
                        <label className="mb-3 block text-black dark:text-white">
                          {t("First select jumpers u wish to complain about")} :
                        </label>
                        <div className="relative z-20 bg-white dark:bg-form-input">
                          <select
                            name="jumper"
                            id="jumper"
                            value={complainedJumper}
                            onChange={(e) => {
                              setJumper(e.target.value);
                            }}
                            required
                            className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus-border-primary active-border-primary dark-border-form-strokedark dark-bg-form-input"
                          >
                            <option value="" disabled selected>
                              {t("Select jumper")}
                            </option>
                            {jumpers?.map((opt) => (
                              <option
                                key={opt.jumper?._id}
                                value={opt.jumper?._id}
                              >
                                {opt.jumper?.firstName} {opt.jumper?.lastName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5.5">
                      <div>
                        <label className="my-3 block text-black dark:text-white">
                          {" "}
                          {t(
                            "Second select the Date you want to complain about"
                          )}
                          :
                        </label>
                        <div className="relative z-20 bg-white dark:bg-form-input">
                          <Select
                            isMulti
                            name="date"
                            options={dateRange.map((opt) => ({
                              value: opt,
                              label: opt,
                            }))}
                            onChange={selectDate}
                            className="w-full"
                            classNamePrefix="select"
                            required
                            ref={date}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="pb-3 pt-3">
                      <div>
                        <p>{t("Write Your Complaint")}</p>
                        <textarea
                          className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                          name="bio"
                          id="bio"
                          rows={6}
                          ref={complaint}
                          placeholder={t("Write your complaint here")}
                        ></textarea>
                      </div>
                    </div>
                    <div>
                      <button
                        className="flex w-full justify-center rounded bg-danger py-2 px-6 font-medium text-gray hover:shadow-1"
                        type="submit"
                      >
                        {t("Submit Complain")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complain;
