import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

function RequireOrderInfo(props) {
    const order = useSelector(state => state.order);


    if (order.orderInfo) {
        return <Outlet/>
    }
    else{
        return <Navigate to='/orderinfo'/>
    }    
}

export default RequireOrderInfo;