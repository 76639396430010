import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CompleteProfileClient = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-fit flex items-center justify-center">
      <div className="max-w-md p-8 bg-white shadow-lg rounded-md text-center">
        <h2 className="text-3xl font-bold mb-4 text-center">
          {t("Welcome to Jumpers Platform for Freelancing Work")}
        </h2>
        <p className="text-gray-700 mb-4">
          {t(
            "We would like to thank you for choosing our platform and trust it."
          )}
        </p>
        <p className="text-red-500 mb-4">
          {t(
            "Unfortunately, you can't place an order until you choose your city."
          )}
        </p>
        <p className="mb-4">{t("The best is to complete your profile.")}</p>
        <p className="mb-4">
          {t("You can complete your profile from")}
          <Link to="/updateprofile" className="text-primary underline">
            {t("Update profile")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default CompleteProfileClient;
