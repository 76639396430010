/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import card from "../images/cards/cards-02.png";
import { useTranslation } from "react-i18next";
const CreditCards = ({ option, selected, onClick, onChange }) => {
  const { t, i18n } = useTranslation();
  const handleInputChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };
  console.log();
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? "border-solid border-2 border-indigo-600" : "border"
      } flex rounded-sm bg-white py-3 px-3 shadow-default dark:border-strokedark h-50 dark:bg-boxdark cursor-pointer`}
    >
      <div className="p-4 md:p-5 flex flex-col justify-between items-center">
        <h3 className="text-lg font-bold text-black dark:text-white">
          {option.name}
        </h3>
        <div className="h-25 w-full flex justify-center items-center mt-2">
          <img
            src={
              option.image
                ? `https://api.jumpersksa.online//${option.image}`
                : card
            }
            alt="bank image"
            className="w-100 h-full"
          />
        </div>
        <input
          type="hidden"
          value={option._id}
          onChange={handleInputChange} // Call the onChange prop when the value changes
        />
      </div>
    </div>
  );
};
export default CreditCards;
