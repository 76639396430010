import React from "react";
import "./SignIn.css";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
const PrivacyPolicy = () => {
  return (
    <div className="containerOfPP">
      <div className="fc">
        <p className="f">سياسة الخصوصية</p>
        <button
          onClick={() => window.history.back()}
          // to={"/auth/signin"}
          className="prev bgteal rounded-md px-4 py-2 text-graydark flex items-center"
        >
          الرجوع الى الصفحة السابقة{" "}
          <BiArrowBack className="mx-3 text-graydark " fontSize={15} />
        </button>
      </div>

      <hr />
      <p className="ff">
        اتفاقية الشروط والاحكام لموقع جمبرز دوت كوم JUMPERSKSA.COM تمثل تلك
        الشروط والاحكام كل ما يتعلق بوصولك الى منصتنا الالكترونية والية
        استخدامها، ويشار اليها لاحقا باسم (الشروط) او (الشروط والاحكام) وللتسجيل
        لدينا يجب عليك قراءة الشروط والاحكام والموافقة عليها لأنها تمثل الإطار
        القانوني للعمل. التحقق وشروط العضوية يجب استيفاء المستندات الثبوتية
        لأجراء التحقق من انطباق الشروط عليك لاستخدام الموقع ومثال تلك المستندات
        هي "بطاقة الهوية الوطنية وشهادات الترخيص والخبرة" او " مستندات شركتكم
        كالسجل التجاري او مستندات التأسيس" ويشترط لقبول عضويتك في موقعنا الا
        يكون عمرك اقل من 18 عام والا تتضمن كنيتك او اسمك كلمة" جمبرز" او
        ”JUMPERSKSA“ والتزامك بالإفصاح عن معلوماتك الحقيقية للتسجيل بالموقع
        واستخدامك بريد اليكترونى ورقم جوال واحد فقط ، وفى كل الاحوال يحق لأداره
        الموقع في أي وقت طلب اية وثائق إضافية للتحقق من تطبيق شروط العضوية
        المذكورة. الاعمال والخدمات الاعمال والخدمات المسموح بها: نسمح بكافة
        الاعمال/ الخدمات ت التي توفى احتياجات الشركات/أصحاب الاعمال بكافة
        انواعها المهنية والتقنية والفنية والادبية وغيرها. الاعمال والخدمات
        المحظورة غير المسموح بها: يحظر تقديم كافة الاعمال/الخدمات المخالفة
        للشريعة الإسلامية او المخالفة للأنظمة المعمول بها بالمملكة العربية
        السعودية. متابعة أداء الاعمال والخدمات: لإدارة الموقع المتابعة والاشراف
        لأداء الاعمال/الخدمات المقدمة ويلتزم المنتسبين بتفعيل بدء/انتهاء
        الاعمال/ الخدمات عن طريق تطبيق اللوكيشن الذي قام بتطويره الموقع لضبط
        العمل. الحقوق والالتزامات حقوق والتزامات مؤدي الاعمال/الخدمات: يتمتع
        مؤدي الاعمال/ الخدمات بكافة الحقوق المقررة وفقا للأنظمة السعودية
        والاحكام العامة المتعلقة بالعمل نظير مقابل مادي، وعليه يكونوا ملتزمين
        بأداء الاعمال المطلوبة على النحو الأمثل وبإتقان تام والحرص على أداء تلك
        الاعمال/الخدمات على أكمل وجه وعدم مخالفة الشروط والاحكام وتحمل المسئولية
        النظامية الكاملة عن كافة الاعمال/ الخدمات التي يتم تقديمها والحرص على
        عدم الاضرار بالموقع، وتجنب عرض الاعمال/الخدمات الوهمية او الاحتيالية،
        وعدم تفويض الغير للتعامل على الحساب الشخصي او الدخول على صفحته الا إذا
        كان ممثلا قانونيا لكيان اعتباري او شخصية قانونية قائمة ومسجلة على
        الموقع، ويلتزم العضو تحديث بيانات التسجيل في حالة طرأت ثمة تغييرات وذلك
        للإبقاء على صلاحية الحساب الشخصي وفى حالة حدوث أي مشكلة يجب على العضو
        ابلاغ إدارة الموقع خلال (2) ساعات من حدوثها وذلك حتى تتمكن إدارة الموقع
        من اتخاذ الاجراء اللازم. حقوق والتزامات المؤدي لهم الاعمال/الخدمات:
        لأصحاب الشركات/ الاعمال كافة الحقوق المقررة لتلقي الاعمال /الخدمات
        المقدمة عبر موقعنا ويكونوا ملتزمين ب تحديد الاعمال/الخدمات المطلوبة بشكل
        دقيق من مؤدي الخدمات عن طريق الموقع، وتحديد المقابل المادي والالتزام
        بسدادها بطرق الدفع المقرر لدينا، وعليهم الالتزام بعدم التعامل في أي أمور
        مادية/مالية او طلب خدمات اضافية خارج منصة الدفع الالكتروني للموقع، وعدم
        مخالفة اتفاقية الشروط والاحكام، التعامل على الحساب الشخصي بالموقع بشكل
        فردى وعدم تفويض الغير ل لتعامل على الحساب الشخصي او الدخول على صفحته الا
        إذا كان ممثلا قانونيا لكيان اعتباري او شخصية قانونية قائمة ومسجلة على
        الموقع، ويلتزم العضو تحديث بيانات التسجيل في حالة طرأت ثمة تغييرات وذلك
        للإبقاء على صلاحية الحساب الشخص ي وفى حالة حدوث أ ي مشكلة يجب على العضو
        ابلاغ إدارة الموقع خلال( (2 ساعات من حدوثها وذلك حتى تتمكن إدارة الموقع
        من اتخاذ الاجراء اللازم. حقوق الملكية الفكرية: يمتلك الموقع/ التطبيقات
        التابعة له كافة حقوق الملكية الفكرية لعناصرها البرمجية والفنية وأسلوب
        عرض المحتوي بما تراه مناسبا وهو مملوك له ملكية خالصة وحصرية محفوظة ويمنع
        استخدام أي حقوق متعلقة بذلك بأي شكل من الأشكال الا للغرض الذي خصصت من
        اجله وفقا لطبيعة العمليات التي يقومون بها على الموقع فقط، وتحتفظ إدارة
        الموقع بالحق المطلق في التعديل على الموقع او صياغة/إضافة/إلغاء/تعديل
        شروط وأحكام هذه الاتفاقية كليا او جزئيا على اى من محتويات الموقع. الجودة
        والضمان: يعتمد الموقع سياسة الجودة لمستوي أداء الاعمال/الخدمات التي يتم
        تقديمها من خلاله ويجوز الإبلاغ عنها في حالات الخلل في أدائها بالمخالفة
        للوصف/ الاتفاق مع المنسبين الباحثين عن العمل، ويشترط في كلتا الحالتين ان
        تقوم الشركة/ صاحب العمل بإبلاغ الموقع خلال مدة (2) ساعات من وقت حدوث
        الاخلال/المخالفة، وفى حالة عدم القيام بذلك يعتبر العمل/الخدمة مؤداه
        ويستحق مقابلها المادي، ويحق للموقع توقيع الجزاء على المنتسب ( مؤدي
        العمل/ الخدمة) الذى يخل بالتزامه تجاه المنتسب (المؤدي لديه العمل/الخدمة)
        ففضلا عن ارجاع قيمة الخدمة للمؤدي لديه العمل/الخدمة والغاء العملية تستحق
        لإدارة الموقع قيمة العمولة عن الاعمال/ الخدمات الملغية ويتم خصم قيمتها
        من مستحقات مؤدي الخدمة من الاعمال الحالية او الجارية او المستقبلية دون
        قيد او شرط، ويحق للموقع ان يقوم باتخاذ أي اجراء من شأنه وقف حساب المنسب
        (مؤدي الخدمة) او تنبيهه او مراجعته في أي شيء يخص ذلك. سابعا: الإعلانات:
        يجوز لإدارة الموقع القيام بعرض روابط خارجية او تقديم خدمات الاعلانات
        المبوبة المدفوعة على الموقع دون احقية المنتسبين في الاعتراض على أي شيء
        يخص ذلك، مع احقية المنتسبين في الاستفادة من تلك الإعلانات بالشروط
        الاعلانية التي تقرها إدارة الموقع. الرسوم والمعاملات المالية: للموقع
        عمولة نسبية على كل الاعمال/ الخدمات التي يتم اداؤها ويحق لها التعديل علي
        قيمتها او تغييرها بشكل مؤقت أو مستمر وتحتسب العمولة بالريال السعودي ويتم
        سدادها عبر منظومة الدفع الخاصة بالموقع، ويتم استحقاق العمولة مضافاً
        إليها أي ضرائب أو نفقات أخرى بمجرد التوافق على تنفيذها، ويحق للموقع
        إيقاف الحساب بشكل مؤقت أو دائم في حالة الاخلال بالسداد، وتتم التحويلات
        للمنتسب (مؤدي العمل/الخدمة) يوم الثلاثاء من كل أسبوع التالي لتاريخ
        استقبال قيمة الخدمة من (المؤدي له العمل/الخدمة)، والموقع غير مسئول عن
        اية مدفوعات/تعاملات/مراسلات تتم خارج الموقع وفى حالة ثبوت ذلك يحق لإدارة
        الموقع توقيع الجزاءات التي قد تصل الى الغاء العضوية او تجميدها، بالإضافة
        الي احقيتها في المطالبة باي مستحقات مالية تترتب على ذلك وطلب التعويض من
        خلال اتخاذ الإجراءات النظامية التي يقررها النظام. سرية البيانات: يلتزم
        الموقع بالتعامل مع معلوماتك الشخصية عناوين الاتصال الخاصة حساباتك
        المالية والتفاصيل المتعلقة بعمليات الدفع بسريّة تامة. الروابط الي مواقع
        اخري: قد يحتوي الموقع/ التطبيق على روابط خارجية مملوكة لطرف ثالث غير
        مملوكة لنا ولا نتحكم في مضمونها ولا نتحمل أي مسئولية عنها بشكل مباشر او
        غير مباشر. الامان والخصوصية: يحق لأي منتسب للموقع القيام بأية تعديلات
        متعلقة بالأمان والخصوصية طالما لا تتعارض مع الشروط والاحكام بداية من
        تعديل كلمة السر وتأكيدها عبر البريد الإليكتروني حتى امكانية التواصل مع
        الدعم الفني من اجل اصلاح اية اخطاء تقنية او عمليات دخول غير قانونية.
        الاطار القانوني للعمل والفصل بالنزاعات: يحق لإدارة الموقع اجراء
        التحقيقات الضرورية والفصل في النزاعات المتعلقة بطلب ومباشرة وتسليم
        وتنفيذ الاعمال/ الخدمات التي تتم من خلاله ومالم يرد في مضمون الاتفاقية
        او تفسيرها او الفصل في صلاحية بنودها يخضع لقواعد قانون التجارة
        الالكترونية وقواعد المنظمة العالمية لحقوق الملكية الفكرية (WIPO) ونظام
        مكافحة جرائم المعلوماتية ونظام حماية التعاملات الإلكترونية وكافة
        القوانين المحلية ذات الصلة بالمملكة العربية السعودية، وللموقع احقية
        الغاء الحساب وانهاء تلك الاتفاقية بدون ابداء أسباب وبدون أي مسئولية
        قانونية. تعديل اتفاقية الشروط والاحكام: يحق لإدارة الموقع اجراء اية
        تعديلات أساسية أو فرعية على هذه الاتفاقية دون الحصول على موافقة
        المنتسبين. ……………………………………………………. JUMPERSKSA.COM جميع الحقوق محفوظة على
        محتوى الموقع وهذه الاتفاقية ولا يجوز نسخها واعادة استخدامها الدعم الفني-
        يمكنكم التواصل معنا يوميا على مدار 24 ساعة يسعدنا استقبال جميع
        استفساراتكم
      </p>
    </div>
  );
};

export default PrivacyPolicy;
