import React from "react";
import OfferCard from "../../components/OfferCard";

const NewOffers = () => {
  const services = [
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
    {
      id: 1,
      serviceName: "ServiceTest",
      price: 999,
      city: "Makkah",
      startDate: "12-12-2222",
      endDate: "12-12-2222",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        {services.map((service) => (
          <>
            <OfferCard service={service} key={service.id} />
          </>
        ))}
      </div>
    </>
  );
};

export default NewOffers;
