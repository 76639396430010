import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../axiosInst";
import { toast } from "react-toastify";
import "./Orders.css";
import PrivacyModal from "../../components/PrivacyModal";
import { useTranslation } from "react-i18next";

const ClientOrderDetails = ({ socket }) => {
  const { t, i18n } = useTranslation();
  const [order, setOrder] = useState();
  const data = localStorage.getItem("role");

  const navigate = useNavigate();
  const { id } = useParams();

  const handlePay = async () => {
    if (order.jumpers.length) {
      if (order.isPaid) {
        toast.warning(t("Order is already paid"));
      } else {
        const result = await axiosInstance.get(`/orders/pay_order_url/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        console.log(result.data.URL);
        window.open(result.data.URL);
      }
    } else {
      toast.error(t("wait until jumpers accept offers"));
    }
  };

  const handleClick = () => {
    navigate("/myorders");
  };

  const getOrder = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/orders/user_order/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        console.log(res?.data?.data);
        setOrder(res?.data?.data);
      } catch (error) {
        if (error?.response?.data?.err) {
          toast.error(error?.response?.data?.err);
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  useEffect(() => {
    socket.on("update order recieved", () => {
      getOrder();
    });
  }, []);
  useEffect(() => {
    axiosInstance.defaults.headers.common["lang"] = i18n.language;
    getOrder();
  }, [i18n.language]);
  console.log(order);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const navigateToComplain = (status) => {
    if (status === "inReview") {
      navigate(`/complain/${order?._id}`);
    } else {
      toast.error(
        `${t("you can't make a complain while order status is")} ${status}`
      );
    }
  };
  const navigateToReview = (status) => {
    if (status === "inReview") {
      navigate(`/jumperreview/${order?._id}`);
    } else {
      toast.error(
        `${t("you can't review jumpers while order status is")} ${status}`
      );
    }
  };
  const getOrderStatus = (status) => {
    switch (status) {
      case "pending":
        return "pending";
      case "inReview":
        return t("inReview");
      case "drafted":
        return t("Drafted");
      case "completed":
        return t("Completed");
      case "started":
        return t("Started");
      default:
        return "pending";
    }
  };
  const changeNameOfGender = (name) => {
    switch (name) {
      case "all":
        return t("All");
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("All");
    }
  };
  const changeNameOfNationalities = (name) => {
    switch (name) {
      case "all":
        return t("All Nationalities");
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("All Nationalities");
    }
  };
  return (
    <>
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Order Details")} />

        {data === "client" && (
          <>
            <div className="mb-3 rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark md:p-6 xl:p-9">
              {order && (
                <div className="flex flex-col gap-7.5">
                  {/* <!-- Alerts Item --> */}
                  {order?.orderStatus === "drafted" ? (
                    <>
                      <div className="flex w-full border-l-6 border-[#d33434] bg-[#d33c34a7] bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-[#d33c342c] dark:bg-opacity-10 md:p-9">
                        <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#a33333]">
                          <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                              fill="white"
                              stroke="white"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <p className=" leading-relaxed text-body mx-1 text-lg">
                            {t("Order is Drafted")}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {order?.isPaid ? (
                        <div className="flex w-full border-l-6 border-[#34D399] bg-[#34D399] bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-[#34D399] dark:bg-opacity-30 md:p-9">
                          <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#34D399]">
                            <svg
                              width="16"
                              height="12"
                              viewBox="0 0 16 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                                fill="white"
                                stroke="white"
                              ></path>
                            </svg>
                          </div>
                          <div className="w-full">
                            <p className=" leading-relaxed text-body mx-1 text-lg">
                              {t("Order Paid Successfully")}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex w-full border-l-6 border-warning bg-warning bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-warning dark:bg-opacity-30 md:p-9">
                          <div className="mr-5 flex h-15 w-15 items-center justify-center rounded-lg bg-warning bg-opacity-30">
                            <svg
                              width="25"
                              height="25"
                              viewBox="0 0 19 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                                fill="#FBBF24"
                              ></path>
                            </svg>
                          </div>
                          <div className="w-full">
                            <p className="leading-relaxed text-[#D0915C] mx-1 text-lg">
                              <p>{t("Order Not Paid Yet")}</p>
                              {order.jumpers.length < 1 ? (
                                <p>
                                  {t("Waiting for jumpers to accept offers")}
                                </p>
                              ) : (
                                <p>{t("Waiting for you to pay")}</p>
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            {showPrivacyModal && (
              <div className="privacyModalContainer">
                <PrivacyModal
                  closeModal={() => setShowPrivacyModal(false)}
                  onSubmit={() => {
                    setShowPrivacyModal(false);
                  }}
                  updateOrder={getOrder}
                  defaultValue
                  socket={socket}
                />
              </div>
            )}
          </>
        )}
        {data === "client" && (
          <>
            {order?.orderStatus !== "drafted" && (
              <div className="w-full buttonsContainer mt-15 mb-3 rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark md:p-6 xl:p-9">
                <div>
                  <button
                    className="rounded bgteal py-2 px-6 font-medium text-gray "
                    type="submit"
                    // onClick={handlePay}
                    onClick={() => setShowPrivacyModal(true)}
                  >
                    {t("Pay Order")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigateToReview(order?.orderStatus);
                    }}
                    className="rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1 buttonInBC"
                  >
                    {t("Review jumpers")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigateToComplain(order?.orderStatus);
                    }}
                    className=" rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1 buttonInBC"
                  >
                    {t("make a complain")}
                  </button>
                </div>
                <div>
                  <Link to={`/jumpersstatus/${order?._id}`}>
                    <button className=" rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1 buttonInBC">
                      {t("jumpers status")}
                    </button>
                  </Link>
                </div>
                <div>
                  <Link to={`/selectmore/${order?._id}`} target="_blank">
                    <button className=" rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1 buttonInBC">
                      {t("Select More Jumpers")}
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </>
        )}

        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Order Info Reviewing")}
                </h3>
              </div>
              <div className="p-7">
                <div class="grid grid-cols-1 divide-y ">
                  <div class="flex flex-row justify-between pb-3">
                    <div>
                      <p>{t("Service Name")}</p>
                    </div>
                    <div>
                      <p>{order?.service?.name}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Number Of Jumbers")}</p>
                    </div>
                    <div>
                      <p>{order?.jumpers?.length}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Nationality")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfNationalities(
                          order?.orderInfo?.JumpersNationality
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Gender")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfGender(order?.orderInfo?.JumpersGender)}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Status")}</p>
                    </div>
                    <div>
                      <p
                        className={`inline-flex rounded-full  bg-opacity-10 py-1 px-3 text-sm font-medium  
                   ${
                     order?.orderStatus === "pending"
                       ? "bg-warning text-warning"
                       : order?.orderStatus === "drafted"
                       ? "bg-danger text-danger"
                       : "bg-success text-success"
                   }
                  `}
                      >
                        {}
                        {getOrderStatus(order?.orderStatus) === "pending"
                          ? order?.isPaid
                            ? t("waiting to start")
                            : t("waiting to pay")
                          : getOrderStatus(order?.orderStatus)}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("City")}</p>
                    </div>
                    <div>
                      <p>{order?.orderInfo?.city?.name}</p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Address")}</p>
                    </div>
                    <div>
                      <p>{order?.orderInfo?.location}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Age Range")}</p>
                    </div>
                    <div>
                      <p>
                        {t("From")} {order?.orderInfo?.JumpersAgeMin} {t("To")}{" "}
                        {order?.orderInfo?.JumpersAgeMax}{" "}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Skills")}</p>
                    </div>
                    <div>
                      <p className="mx-1">
                        {order?.orderInfo?.JumpersSkills?.map((s) => {
                          return <span className="mx-1">{s.name}</span>;
                        })}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Work Description")}</p>
                    </div>
                    <div>
                      <p>{order?.workDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="rounded-sm border mb-4 border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="flex justify-around">
                <div className="bg-white dark:bg-boxdark text-center w-full p-2">
                  <p className="text-center font-bold text-lg">
                    {t("Number Of Jumbers")}
                  </p>
                  <hr className="px-4 my-3 text-black dark:text-white opacity-50" />
                  <p>
                    <span className="">{order?.jumpers?.length}</span>
                  </p>
                </div>
                {/* span for line between two divs */}
                <span className="border-r border-stroke"></span>
                <div className="bg-white dark:bg-boxdark text-center w-full p-2">
                  <p className="text-center font-bold text-lg">{t("Status")}</p>
                  <hr className="px-4 my-3 text-black dark:text-white opacity-50" />
                  <p>
                    {getOrderStatus(order?.orderStatus) === "pending"
                      ? order?.isPaid
                        ? t("waiting to start")
                        : t("waiting to pay")
                      : getOrderStatus(order?.orderStatus)}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Timing And Costing Reviewing")}
                </h3>
              </div>
              <div className="p-7">
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Hours")}</p>
                  </div>
                  <div>
                    <p>
                      {order?.timeAndCost?.workHours} {t("hours")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Days")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost?.dayNo}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Start Date")}</p>
                  </div>
                  <div>
                    <p>
                      {order &&
                        new Date(order.startDate).toLocaleString("en-AU")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("End Date")}</p>
                  </div>
                  <div>
                    <p>
                      {order && new Date(order.endDate).toLocaleString("en-AU")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Suggested Cost")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost?.packagePrice}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Jumper Price")}</p>
                  </div>
                  <div>
                    <p>{order?.timeAndCost?.jumperPrice}</p>
                  </div>
                </div>
                {data === "client" && (
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("VAT Cost")}</p>
                    </div>
                    <div>
                      <span className="font-bold">({order?.taxRatio}%)</span>
                      <span>
                        {/* {(order?.taxRatio / 100) *
                          order?.timeAndCost?.jumperPrice *
                          order?.timeAndCost?.dayNo *
                          order?.jumpersOfferNumber} */}
                        {order?.taxPrice}
                      </span>
                    </div>
                  </div>
                )}
                {data === "jumper" && (
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>Site Ratio</p>
                    </div>
                    <div>
                      (
                      {order?.timeAndCost?.jumperPrice *
                        (order?.siteRatio / 100)}
                      ){order?.siteRatio} %
                    </div>
                  </div>
                )}
                <div class="flex flex-row justify-between pb-3 pt-3">
                  {data === "jumper" && (
                    <div>
                      <p>{t("Total Cost")}</p>
                    </div>
                  )}
                  {data === "client" && (
                    <div>
                      <p>{t("Total Cost with VAT")}</p>
                    </div>
                  )}
                  {data === "jumper" && (
                    <div>
                      <p>
                        {order?.timeAndCost?.jumperPrice -
                          order?.timeAndCost?.jumperPrice *
                            (order?.siteRatio / 100)}
                      </p>
                    </div>
                  )}
                  {data === "client" && (
                    <div>
                      <p>
                        {/* {order?.timeAndCost?.jumperPrice *
                          order?.timeAndCost?.dayNo *
                          order?.jumpersOfferNumber +
                          0.15 *
                            order?.timeAndCost?.jumperPrice *
                            order?.timeAndCost?.dayNo *
                            order?.jumpersOfferNumber} */}
                        {order?.totalPrice}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientOrderDetails;
