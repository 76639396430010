import React from "react";
import "./Complaint.css";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader";
import Breadcrumb from "../../components/Breadcrumb";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import noOffers from "../../images/product/SEO.webp";
import { FaGreaterThan } from "react-icons/fa6";
import { FaLessThan } from "react-icons/fa6";
import { GrNext, GrPrevious } from "react-icons/gr";
const Complaint = () => {
  const { t, i18n } = useTranslation();
  const data = localStorage.getItem("role");
  const { userInfo } = useSelector((state) => state.user);
  const [claims, setClaims] = useState([]);
  const limit = 4;
  const [page, setPage] = useState(1);
  const [numOfPage, setNumOfPage] = useState(0);
  const [documentsCounts, setDocumentsCounts] = useState(0);
  const [pages, setPages] = useState();
  const [results, setResults] = useState(0);
  const [lastPage, setLastPage] = useState();
  useEffect(() => {
    axios
      .get(
        `https://api.jumpersksa.online/claims/user_claims?page=${page}&limit=${limit}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        setLoading(false);
        setClaims(res?.data?.data);
        // setNumOfPage(res?.data?.paginationResult?.numberOfPages);
        if (res?.data?.paginationResult?.numberOfPages > 5) {
          setNumOfPage(5);
        } else {
          setNumOfPage(res?.data?.paginationResult?.numberOfPages);
        }
        setDocumentsCounts(res?.data?.documentsCounts);
        setPages(res?.data?.documentsCounts);
        setLastPage(res?.data?.paginationResult?.numberOfPages);
        setResults(res?.data?.results);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page]);
  let pagesArr = [];
  // useEffect(() => {
  //   if (numOfPage > 5) {
  //     setNumOfPage(5);
  //   }
  // }, [numOfPage]);

  for (let i = 1; i <= numOfPage; i++) {
    pagesArr.push(i);
  }
  console.log(pagesArr, numOfPage);

  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrev = () => {
    setPage(page - 1);
  };
  const getComplaintStatus = (status) => {
    switch (status) {
      case "pending":
        return t("Pending");
      case "accepted":
        return t("Accepted");
      case "rejected":
        return t("Rejected");
      default:
        return "pending";
    }
  };
  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <>
      {claims.length > 0 ? (
        <>
          <Breadcrumb pageName={t("All Complaints")} />

          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto text-center">
                <thead>
                  <tr className="bg-gray-2 dark:bg-meta-4 ">
                    <th className="min-w-[50px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("No.")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Jumper name")}
                    </th>
                    <th className="min-w-[120px] max-w-[200px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Description")}
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("Complaint status")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white ">
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {claims?.map((e, index) => (
                    <tr key={e?._id}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {index + 1}
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {e?.jumper?.firstName} {e?.jumper?.lastName}
                      </td>
                      <td className="border-b min-w-[120px] max-w-[200px] overflow-auto border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11 ">
                        <span>{e?.desc}</span>
                      </td>
                      <td className="py-5 px-4">
                        <p
                          className={`inline-flex rounded-full  bg-opacity-10 py-1 px-3 text-sm font-medium  
                     ${
                       e?.status === "pending"
                         ? "bg-warning text-warning"
                         : e.status === "rejected"
                         ? "bg-danger text-danger"
                         : "bg-success text-success"
                     }
                    `}
                        >
                          {getComplaintStatus(e?.status)}
                          {/* {e?.status} */}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center justify-center space-x-3.5">
                          <Link to={`/complaintDetails/${e?._id}`}>
                            <button className="hover:text-primary">
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                  fill=""
                                />
                                <path
                                  d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex lg:flex-row justify-between mt-3 items-center">
            <nav
              aria-label="Pagination"
              class="flex justify-center items-center text-gray-600 mt-8 lg:mt-0"
            >
              {/* <button
                onClick={handlePrev}
                disabled={page === 1}
                className="p-2 mr-4 rounded hover:bg-gray-100"
              >
                {i18n.language === "ar" ? (
                  <FaGreaterThan size={18} />
                ) : (
                  <FaLessThan size={18} />
                )}
              </button>
              <p>
                {t("Page")} {page}
              </p>
              <button
                onClick={handleNext}
                disabled={page === numOfPage}
                class="p-2 ml-4 rounded hover:bg-gray-100"
              >
                {i18n.language === "ar" ? (
                  <FaLessThan size={18} />
                ) : (
                  <FaGreaterThan size={18} />
                )}
              </button> */}
              <div
                className="flex justify-around items-center bg-gradient-to-r from-[#000]  to-[#517d8a] text-white rounded-3xl p-2"
                dir="ltr"
              >
                <button
                  onClick={handlePrev}
                  disabled={page === 1}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === 1 ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === 1 ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrPrevious size={15} />
                  </div>
                </button>
                {pagesArr.map((page) => (
                  <div
                    onClick={() => setPage(page)}
                    className="flex justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                  >
                    <div
                      className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                        page === page ? "bg-white text-black" : ""
                      }`}
                    >
                      {page}
                    </div>
                  </div>
                ))}
                {lastPage > 5 && (
                  <>
                    ...
                    <div
                      onClick={() => setPage(lastPage)}
                      className="flex justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                    >
                      <div
                        className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                          page === page ? "bg-white text-black" : ""
                        }`}
                      >
                        {lastPage}
                      </div>
                    </div>
                  </>
                )}

                <button
                  onClick={handleNext}
                  disabled={page === lastPage}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === lastPage ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === lastPage ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrNext size={15} />
                  </div>
                </button>
              </div>
            </nav>
            <p>
              {t("showing")} <span className="text-primary">{results}</span>{" "}
              {t("pages")} {t("of")}{" "}
              <span className="text-primary">{pages}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center my-5">
            <div className="m-auto w-4/5 flex justify-center">
              <img
                src={noOffers}
                alt="No Offers Available"
                className="w-50 h-50 "
              />
            </div>
            <div className="text-center">
              <p className="font-bold text-lg">
                {t("Sorry! you don't have complains Yet")}
              </p>
              {/* <p>{t("complete your profile,")}</p> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Complaint;
