import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Alert, Col, Row } from "react-bootstrap";
import { useRef, useState } from "react";
import "./SignIn.css";
import logo from "../../images/logo/logo (1).png";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEyeInvisible, AiOutlinePhone } from "react-icons/ai";
import signInImg from "../../images/authPics/login.png";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    dir: "ltr",
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "sa",
    dir: "rtl",
  },
];
const SignIn = () => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const showPage1 = () => setCurrentPage(1);
  const showPage2 = () => setCurrentPage(2);
  const showPage3 = () => setCurrentPage(3);
  const showPage4 = () => setCurrentPage(4);
  const showPage5 = () => setCurrentPage(5);
  const showPage6 = () => setCurrentPage(6);
  const [showEye, setShowEye] = useState(false);

  const verifyEmailCode = useRef();
  const [emailVal, setEmailVal] = useState("");
  const emailRef = useRef(null);
  const [role, setRole] = useState("");
  const passwordRef = useRef(null);
  const emailOTP = useRef();
  const restCode = useRef();
  const newPassword = useRef();
  const confirmNewPassword = useRef();
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrMessage(t("Passwords do not match"));
    } else {
      // Passwords match, you can proceed with your logic here
      setErrMessage("");
      // Perform further actions, like sending data to the server
    }
  };
  const login = () => {
    console.log(`996${emailRef?.current?.value}`, passwordRef?.current?.value);
    // setPhoneNumber(emailRef?.current?.value);
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/login`,
        {
          phoneNumber: `966${emailRef?.current?.value}`,
          password: passwordRef?.current?.value,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data;
        console.log(response?.data);
        var token = response?.data?.token;
        var role = response?.data?.role;
        var userData = response?.data?.userData;

        console.log(`role here ${role}`);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        localStorage.setItem("userData", JSON.stringify(userData));

        toast.success(t("Signed in succefully !"));
        setTimeout(() => {
          navigate("/");
        }, 3000);
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message === "account not active") {
          setCounter(60);
          startCounter();
          showPage5();
          axios
            .post(
              `https://api.jumpersksa.online/users/resend_verify_phone_code`,
              {
                phoneNumber: `966${phoneNumber}`,
              },
              {
                headers: {
                  "content-type": "application/json",
                  Authorization:
                    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
                },
              }
            )
            .then((res) => {
              console.log(res);
              setIsButtonDisabled(false);
              toast.success(res?.data?.message);
            })
            .catch((err) => {
              console.log(err);
              setIsButtonDisabled(true);
              toast.error(err?.response?.data?.message);
              setTimeout(() => {
                setIsButtonDisabled(false);
              }, 3000);
            });
        }
        toast.error(err?.response?.data?.message);
        setIsButtonDisabled(true);
        setErrMessage(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
  };

  const forgetPass = () => {
    showPage2();
  };
  const sendOTP = () => {
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/forgot_password`,
        {
          phoneNumber: `966${emailOTP?.current?.value}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(t("check your phone to get OTP!"), {
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        setIsButtonDisabled(false);
        showPage3();
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        toast.error(err?.response?.data?.message || err?.response?.data?.err);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };
  const verifyResetCode = () => {
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/verify_reset_code`,
        {
          phoneNumber: `966${emailVal}`,
          restCode: restCode?.current?.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res?.data);
        toast.success(t("Valid OTP next Enter the new Password!"), {
          position: toast.POSITION.TOP_CENTER,
        });

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);

        setIsButtonDisabled(false);
        showPage4();
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        setErrMessage(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };
  const resetPassword = () => {
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/reset_password`,
        {
          phoneNumber: `966${emailVal}`,
          newPassword: newPassword?.current?.value,
          confirmNewPassword: confirmNewPassword?.current?.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(t("Password updated Succefully!"), {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        showPage1();
        setIsButtonDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        // setErrMessage(err.response.data.errors[0].msg);
        // setErrMessage(err.response.data.msg);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        setTimeout(() => {
          setErrMessage("");
        }, 3000);
      });
  };
  const [typeOfPass, setTypeOfPass] = useState("password");
  const showPassword = () => {
    setShowEye(true);
    if (typeOfPass === "password") {
      setTypeOfPass("text");
    } else setTypeOfPass("password");
  };
  const [showReSend, setShowRESend] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  let interval;
  const startCounter = () => {
    interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter > 0) {
          console.log(prevCounter);
          return prevCounter - 1;
        } else {
          clearInterval(interval);
          setIsActive(true);
          return 0;
        }
      });
    }, 1000);
  };
  const verifyEmail = () => {
    console.log(phoneNumber);
    setShowRESend(true);
    setIsButtonDisabled(true);
    axios
      .post(
        `https://api.jumpersksa.online/users/verify_phone`,
        {
          phoneNumber: `966${phoneNumber}`,
          verifyEmailCode: verifyEmailCode?.current?.value,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
          },
        }
      )
      .then((res) => {
        setIsButtonDisabled(false);
        toast.success(t("Phone is verified succefully please signIn again"));
        showPage6();
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        toast.error(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
  };
  const reSendOTP = () => {
    setCounter(60);
    startCounter();
    axios
      .post(
        `https://api.jumpersksa.online/users/resend_verify_phone_code`,
        {
          phoneNumber: `966${phoneNumber}`,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTA0OTkwMDVjZTY0ZjkyNjU1OGQ1NjYiLCJ1c2VyUm9sZSI6Imp1bXBlciIsImlhdCI6MTY5NDkwNjIwNywiZXhwIjoxNjk1NTExMDA3fQ.j_LI6JiAw22DFbCU0XuFq_IRV22CCXceLKTHKosdI5s",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setIsButtonDisabled(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(true);
        toast.error(err?.response?.data?.message);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
      });
    setIsActive(false);
  };
  ////////////////////////////
  const currentLang = Cookies.get("i18next") || "ar";
  const currentLanguage = languages.find((l) => l.code === currentLang);
  // const { t, i18n } = useTranslation();
  const handleLanguageChange = (languageCode) => {
    // window.location.reload();
    i18n.changeLanguage(languageCode);
    // Use i18n to change the language
  };
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "rtl";
  }, [currentLanguage.dir]);
  ////////////////////////////
  return (
    <>
      <div className="rounded-sm border min-h-screen border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-wrap items-center">
          <div className=" w-full xl:block xl:w-1/2">
            <Link
              active
              className="focus:underline flex justify-center items-center text-dark dark:text-white bg-transparent rounded "
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-globe2"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                </svg>
              </i>
              {/* <span id="change-lang">English</span> */}
              <select
                name="lang"
                id="lang"
                className="bg-transparent text-dark"
                value={currentLang}
                onChange={(e) => handleLanguageChange(e.target.value)}
              >
                {languages?.map(({ code, name, country_code }) => (
                  <option
                    key={country_code}
                    value={code}
                    id="optLang"
                    className="text-dark bg-black"
                  >
                    {name}
                  </option>
                ))}
              </select>
            </Link>
            <div className="py-5.5 px-5 text-center">
              <div id="logoAndText">
                <Link className="inline-block" to="/">
                  <img
                    className="hidden dark:block"
                    src={logo}
                    alt="Logo"
                    id="logo"
                  />
                  <img
                    className="dark:hidden"
                    src={logo}
                    alt="Logo"
                    id="logo"
                  />
                </Link>

                <p
                  className="2xl:px-3 text-black dark:text-white"
                  id="textinSignIn"
                >
                  جمبرز منصة تفاعلية ووسيط هادف
                </p>
              </div>

              <span className="flex justify-center items-center">
                <img
                  src={signInImg ? signInImg : "not avaliable"}
                  alt="signIn"
                  className="max-xl:max-w-[40%]"
                />
              </span>
            </div>
          </div>

          <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
            {currentPage === 1 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                {/* <span className="mb-1.5 block font-medium">
                  {t("Start for free")}
                </span> */}
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Sign In to Jumpers")}
                </h2>
                <form>
                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white ">
                      {t("Phone")}
                      <small className="linkCC">
                        {t(
                          "(Note: you don't have to enter '996' in the start)"
                        )}
                      </small>
                    </label>
                    <div className="relative" dir="ltr">
                      <div className="w-full flex">
                        <span className="text-gray-500 spanPhone dark:text-gray-400 px-2.5 text-black dark:text-white border-stroke bg-transparent outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                          +966
                        </span>
                        <input
                          ref={emailRef}
                          type="number"
                          onChange={(e) => setPhoneNumber(e?.target?.value)}
                          placeholder={t("Enter your phone")}
                          required
                          className={
                            i18n.language === "ar"
                              ? "w-full inputPhone text-black dark:text-white border border-stroke bg-transparent py-4 pr-6 pl-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                              : "w-full inputPhone border border-stroke text-black dark:text-white bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          }
                        />
                      </div>

                      <span
                        className={
                          "absolute right-4 top-4 text-black dark:text-white "
                        }
                      >
                        <AiOutlinePhone fontSize={25} />
                      </span>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Password")}
                    </label>
                    <div className="relative">
                      <input
                        ref={passwordRef}
                        required
                        minLength={8}
                        type={typeOfPass}
                        placeholder={t("6+ Characters, 1 Capital letter")}
                        className={
                          "w-full rounded-lg text-black dark:text-white border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        }
                      />
                      {!showEye ? (
                        <AiFillEyeInvisible
                          className={`text-black dark:text-white
                        ${
                          i18n.language === "ar"
                            ? "absolute left-5 top-4.5 mx-2"
                            : "absolute right-9 top-4.5 mx-2"
                        }`}
                          id="anthorIcon"
                          onClick={showPassword}
                          size={20}
                        />
                      ) : (
                        <FaEye
                          size={20}
                          className={`text-black dark:text-white
                        ${
                          i18n.language === "ar"
                            ? "absolute left-5 top-4.5 mx-2"
                            : "absolute right-9 top-4.5 mx-2"
                        }`}
                          id="anthorIcon"
                          onClick={() => {
                            setShowEye(false);
                            setTypeOfPass("password");
                          }}
                        />
                      )}

                      <span className="absolute right-4 top-4 text-black dark:text-white">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-5">
                    <input
                      onClick={login}
                      disabled={isButtonDisabled}
                      type="submit"
                      value={t("Sign In")}
                      id="submitBtn"
                      className="w-full cursor-pointer rounded-lg border p-4 text-white transition hover:bg-opacity-90"
                    />
                  </div>

                  <div className="mt-6 text-center text-black dark:text-white">
                    <p>
                      {t("Don’t have any account?")}{" "}
                      <div>
                        <Link to="/auth/signupclient" className="mx-1 linkCC">
                          {t("Sign Up as client")}{" "}
                        </Link>
                        {t("Or you can")}{" "}
                        <Link to="/auth/signup" className="mx-1 linkCC">
                          {t("Sign Up as jumper")}{" "}
                        </Link>
                      </div>
                    </p>
                  </div>
                  <div className="separator text-black dark:text-white">
                    <span className="px-4">{t("Or")}</span>
                  </div>
                  <div className="mt-6 text-center text-black dark:text-white">
                    <p>
                      {t("Did you")}{" "}
                      <Link onClick={forgetPass} className="linkCC">
                        {t("Forget Password?")}
                      </Link>{" "}
                    </p>
                  </div>
                </form>
              </div>
            )}
            {currentPage === 2 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Please Enter your Phone to get OTP")}
                </h2>
                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    {t("Phone")}
                  </label>
                  {/* <div className="relative">
                    <input
                      ref={emailOTP}
                      type="text"
                      placeholder="9665.."
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />

                    <span className="absolute right-4 top-4">
                      <AiOutlinePhone id="phoneIcon" />
                    </span>
                  </div> */}
                  <div className="relative">
                    <div className="w-full flex">
                      <span className="text-gray-500 spanPhone dark:text-gray-400 px-2.5 border-stroke bg-transparent outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                        +966
                      </span>

                      <input
                        ref={emailOTP}
                        type="number"
                        placeholder={t("Enter your phone")}
                        required
                        className="w-full inputPhone border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                    </div>

                    <span className="absolute right-4 top-4">
                      <AiOutlinePhone fontSize={25} />
                    </span>
                  </div>
                </div>

                <div className="mb-5">
                  <input
                    onClick={() => {
                      sendOTP();
                      setEmailVal(emailOTP?.current?.value);
                    }}
                    type="submit"
                    value="Send"
                    className="w-full cursor-pointer rounded-lg border border-primary bgteal p-4 text-white transition hover:bg-opacity-90"
                  />
                </div>
                <small id="smallErr" className="bg-red text-red">
                  {errMessage}
                </small>
              </div>
            )}
            {currentPage === 3 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Now Enter the OTP to Re-Set password")}
                </h2>
                <div className="mb-6">
                  <label className="mb-2.5 block font-medium text-black dark:text-white">
                    OTP:
                  </label>
                  <div className="relative">
                    <input
                      ref={restCode}
                      type="number"
                      placeholder="******"
                      className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />

                    <span className="absolute right-4 top-4">
                      <svg
                        className="fill-current"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g opacity="0.5">
                          <path
                            d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                            fill=""
                          />
                          <path
                            d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                            fill=""
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="mb-5">
                  <input
                    onClick={verifyResetCode}
                    disabled={isButtonDisabled}
                    type="submit"
                    value="Send"
                    className="w-full cursor-pointer rounded-lg border border-primary bgteal p-4 text-white transition hover:bg-opacity-90"
                  />
                </div>
                <small id="smallErr" className="bg-red text-red">
                  {errMessage}
                </small>
              </div>
            )}
            {currentPage === 4 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                {successMessage && (
                  <Row>
                    <Col md={{ span: 3, offset: 8 }}>
                      <Alert key="success" variant="success">
                        {successMessage}
                      </Alert>
                    </Col>
                  </Row>
                )}

                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  {t("Now Enter the new")}
                </h2>
                <form action="" onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Password")}
                    </label>
                    <div className="relative">
                      <input
                        value={password}
                        onChange={handlePasswordChange}
                        ref={newPassword}
                        type="password"
                        placeholder="new pass"
                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />

                      <span className="absolute right-4 top-4">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="mb-6">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("confirm Password")}
                    </label>
                    <div className="relative">
                      <input
                        ref={confirmNewPassword}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        type="password"
                        placeholder="confirm new pass"
                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />

                      <span className="absolute right-4 top-4">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-5">
                    <input
                      onClick={resetPassword}
                      disabled={isButtonDisabled}
                      type="submit"
                      value="Send"
                      className="w-full cursor-pointer rounded-lg border border-primary bgteal p-4 text-white transition hover:bg-opacity-90"
                    />
                  </div>
                  {errMessage && (
                    <p className="text-red-500 text-sm mb-4" id="err">
                      {errMessage}
                    </p>
                  )}
                </form>
              </div>
            )}
            {currentPage === 5 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2 text-center">
                  {t("Verify your Phone Number")}
                </h2>
                <p className="mb-9 text-lg font-bold text-black dark:text-white sm:text-title-lg text-center">
                  {t("you are not active")} <br />
                  {t("so")} <br />
                  {t("below to verify")}
                </p>

                <form>
                  <div className="mb-4">
                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                      {t("Enter OTP")}
                    </label>
                    <div className="relative">
                      <input
                        ref={verifyEmailCode}
                        type="number"
                        placeholder="*****"
                        required
                        className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                      />
                      <span className="absolute right-4 top-4">
                        <svg
                          className="fill-current"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.5">
                            <path
                              d="M16.1547 6.80626V5.91251C16.1547 3.16251 14.0922 0.825009 11.4797 0.618759C10.0359 0.481259 8.59219 0.996884 7.52656 1.95938C6.46094 2.92188 5.84219 4.29688 5.84219 5.70626V6.80626C3.84844 7.18438 2.33594 8.93751 2.33594 11.0688V17.2906C2.33594 19.5594 4.19219 21.3813 6.42656 21.3813H15.5016C17.7703 21.3813 19.6266 19.525 19.6266 17.2563V11C19.6609 8.93751 18.1484 7.21876 16.1547 6.80626ZM8.55781 3.09376C9.31406 2.40626 10.3109 2.06251 11.3422 2.16563C13.1641 2.33751 14.6078 3.98751 14.6078 5.91251V6.70313H7.38906V5.67188C7.38906 4.70938 7.80156 3.78126 8.55781 3.09376ZM18.1141 17.2906C18.1141 18.7 16.9453 19.8688 15.5359 19.8688H6.46094C5.05156 19.8688 3.91719 18.7344 3.91719 17.325V11.0688C3.91719 9.52189 5.15469 8.28438 6.70156 8.28438H15.2953C16.8422 8.28438 18.1141 9.52188 18.1141 11V17.2906Z"
                              fill=""
                            />
                            <path
                              d="M10.9977 11.8594C10.5852 11.8594 10.207 12.2031 10.207 12.65V16.2594C10.207 16.6719 10.5508 17.05 10.9977 17.05C11.4102 17.05 11.7883 16.7063 11.7883 16.2594V12.6156C11.7883 12.2031 11.4102 11.8594 10.9977 11.8594Z"
                              fill=""
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="mb-5">
                    <input
                      disabled={isButtonDisabled}
                      onClick={() => {
                        verifyEmail();
                        startCounter();
                      }}
                      type="submit"
                      value="Verify Account"
                      className="w-full cursor-pointer rounded-lg border bgteal p-4 text-white transition hover:bg-opacity-90"
                    />
                  </div>

                  <>
                    <div className="reSendOTP">
                      {t("if you did not get any OTP try")}
                      <button
                        onClick={reSendOTP}
                        disabled={!isActive}
                        className="sa cursor-pointer"
                      >
                        {t("Re-Send OTP")}
                      </button>{" "}
                      {t("after")} <span className="counter">{counter}</span>{" "}
                    </div>
                  </>
                </form>
              </div>
            )}
            {currentPage === 6 && (
              <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                {/* <span className="mb-1.5 block font-medium">Start for free</span> */}
                <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                  <div className="mt-6 text-center">
                    <p>
                      {t("please sign in again ...")}
                      <div
                        onClick={showPage1}
                        className="text-primary cursor-pointer"
                      >
                        {t("Sign in")}
                      </div>
                    </p>
                  </div>
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
