import { SET_USER, USER_LOGOUT, SET_LOADER } from "../Constants/userConstans";
import { axiosInstance } from "../../axiosInst";
import { useTranslation } from "react-i18next";

export const setUser = (value) => {
  return {
    type: SET_USER,
    payload: value,
  };
};

export const fetchUser = (token, lang) => {
  return async (dispatch) => {
    const response = await axiosInstance.get("/users/user_data", {
      headers: {
        "content-type": "application/json",
        authorization: token,
        lang: lang
      },
    });
    console.log(",mmmmmmmmmmmmmmmm", response);
    dispatch(setUser(response.data.data));
    dispatch(setLoader(false));
  };
};

export const setLoader = (value) => {
  return {
    type: SET_LOADER,
    payload: value,
  };
};
