import React from "react";

const ClientInvoice = () => {
  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
      {/* Grid */}
      <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Invoice
          </h2>
        </div>
        {/* Col */}
        <div className="inline-flex gap-x-2">
          <a
            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
            href="/#"
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            Invoice PDF
          </a>
          <a
            className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            href="/#"
          >
            <svg
              className="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
              <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            Print
          </a>
        </div>
        {/* Col */}
      </div>
      {/* End Grid */}
      {/* Grid */}
      <div className="grid md:grid-cols-2 gap-3">
        <div>
          <div className="grid space-y-3">
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Billed to:
              </dt>
              <dd className="text-gray-800 dark:text-gray-200">
                <a
                  className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                  href="#"
                >
                  sara@site.com
                </a>
              </dd>
            </dl>
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Billing details:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                <span className="block font-semibold">Sara Williams</span>
                <address className="not-italic font-normal">
                  280 Suzanne Throughway,
                  <br />
                  Breannabury, OR 45801,
                  <br />
                  United States
                  <br />
                </address>
              </dd>
            </dl>
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Shipping details:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                <span className="block font-semibold">Sara Williams</span>
                <address className="not-italic font-normal">
                  280 Suzanne Throughway,
                  <br />
                  Breannabury, OR 45801,
                  <br />
                  United States
                  <br />
                </address>
              </dd>
            </dl>
          </div>
        </div>
        {/* Col */}
        <div>
          <div className="grid space-y-3">
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Invoice number:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                ADUQ2189H1-0038
              </dd>
            </dl>
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Currency:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                USD - US Dollar
              </dd>
            </dl>
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Due date:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                10 Jan 2023
              </dd>
            </dl>
            <dl className="grid sm:flex gap-x-3 text-sm">
              <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                Billing method:
              </dt>
              <dd className="font-medium text-gray-800 dark:text-gray-200">
                Send invoice
              </dd>
            </dl>
          </div>
        </div>
        {/* Col */}
      </div>
      {/* End Grid */}
      {/* Table */}
      <div className="mt-8 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
          <div className="col-span-3 flex items-center">
            <p className="font-medium">Product Name</p>
          </div>
          <div className="col-span-2 hidden items-center sm:flex">
            <p className="font-medium">Category</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="font-medium">Price</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="font-medium">Sold</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="font-medium">Profit</p>
          </div>
        </div>

        <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
          <div className="col-span-3 flex items-center">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <p className="text-sm text-black dark:text-white">
                Apple Watch Series 7
              </p>
            </div>
          </div>
          <div className="col-span-2 hidden items-center sm:flex">
            <p className="text-sm text-black dark:text-white">Electronics</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">$269</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">22</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-meta-3">$45</p>
          </div>
        </div>
        <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
          <div className="col-span-3 flex items-center">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <p className="text-sm text-black dark:text-white">
                Macbook Pro M1
              </p>
            </div>
          </div>
          <div className="col-span-2 hidden items-center sm:flex">
            <p className="text-sm text-black dark:text-white">Electronics</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">$546</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">34</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-meta-3">$125</p>
          </div>
        </div>
        <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
          <div className="col-span-3 flex items-center">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <p className="text-sm text-black dark:text-white">
                Dell Inspiron 15
              </p>
            </div>
          </div>
          <div className="col-span-2 hidden items-center sm:flex">
            <p className="text-sm text-black dark:text-white">Electronics</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">$443</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">64</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-meta-3">$247</p>
          </div>
        </div>
        <div className="grid grid-cols-6 border-t border-stroke py-4.5 px-4 dark:border-strokedark sm:grid-cols-8 md:px-6 2xl:px-7.5">
          <div className="col-span-3 flex items-center">
            <div className="flex flex-col gap-4 sm:flex-row sm:items-center">
              <p className="text-sm text-black dark:text-white">
                HP Probook 450
              </p>
            </div>
          </div>
          <div className="col-span-2 hidden items-center sm:flex">
            <p className="text-sm text-black dark:text-white">Electronics</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">$499</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-black dark:text-white">72</p>
          </div>
          <div className="col-span-1 flex items-center">
            <p className="text-sm text-meta-3">$103</p>
          </div>
        </div>
      </div>
      {/* End Table */}
      {/* Flex */}
      <div className="mt-8 flex sm:justify-end">
        <div className="w-full max-w-2xl sm:text-right space-y-2">
          {/* Grid */}
          <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
            <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
              <dt className="col-span-3 text-gray-500">Subotal:</dt>
              <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                $2750.00
              </dd>
            </dl>
            <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
              <dt className="col-span-3 text-gray-500">Total:</dt>
              <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                $2750.00
              </dd>
            </dl>
            <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
              <dt className="col-span-3 text-gray-500">Tax:</dt>
              <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                $39.00
              </dd>
            </dl>
            <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
              <dt className="col-span-3 text-gray-500">Amount paid:</dt>
              <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                $2789.00
              </dd>
            </dl>
            <dl className="grid sm:grid-cols-5 gap-x-3 text-sm">
              <dt className="col-span-3 text-gray-500">Due balance:</dt>
              <dd className="col-span-2 font-medium text-gray-800 dark:text-gray-200">
                $0.00
              </dd>
            </dl>
          </div>
          {/* End Grid */}
        </div>
      </div>
      {/* End Flex */}
    </div>
  );
};

export default ClientInvoice;
