import { lazy } from "react";

const Profile = lazy(() => import("../pages/Profile"));
const Settings = lazy(() => import("../pages/Settings"));

const coreRoutes = [
  {
    path: "/profile",
    title: "Profile",
    component: Profile,
  },
  {
    path: "/settings",
    title: "Settings",
    component: Settings,
  },
];

const routes = [...coreRoutes];
export default routes;
