import React from "react";
import Breadcrumb from "../../src/components/Breadcrumb";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../axiosInst";
import { useEffect } from "react";
import "./style.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ContractModalClient = ({
  closeModal,
  onSubmit,
  defaultValue,
  addJumpers,
  id,
  serviceName,
  jumperPrice,
}) => {
  const { t, i18n } = useTranslation();

  console.log(id);
  return (
    <div
      className="modal-container-two"
      onClick={(e) => {
        if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div className="relative w-full h-fit modal rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto ">
        <div className=" border-stroke py-4 px-7">
          <div className="w-full flex justify-end">
            <strong
              className="text-xl align-center cursor-pointer "
              onClick={closeModal}
            >
              &times;
            </strong>
          </div>{" "}
          <p className="text-2xl m-3">{t("Contract aprovment")}</p>
          <div className="mb-5.5 w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary">
            <p>
              {t("By clicking 'Agree' then you agree on the")}{" "}
              <Link
                target="_blank"
                className="text-primary cursor-pointer"
                to={`/contractsofclient/${id}?service=${serviceName}&price=${jumperPrice}`}
              >
                {t("Contract Formattt")}
              </Link>{" "}
              {t("the Jumpers Platform has submitted")}
            </p>
          </div>
          <div className="flex justify-between items-end gap-4.5 bottom-4 right-4">
            <button
              className="flex justify-center rounded bg-success py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={() => {
                addJumpers();
                closeModal();
              }}
            >
              {t("Agree")}
            </button>
            <button
              className="flex justify-center rounded bg-danger py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={closeModal}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractModalClient;
