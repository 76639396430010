import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

function RequireOrderJumpers(props) {
    const order = useSelector(state => state.order);


    if (order.jumpers?.length) {
        return <Outlet/>
    }
    else{
        return <Navigate to='/selectjumbers'/>
    }    
}

export default RequireOrderJumpers;