import Breadcrumb from "../components/Breadcrumb";

import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Controller } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./Profile.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const EditService = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [cover, setCover] = useState();
  const [newCover, setNewCover] = useState();
  const [description, setDescription] = useState();
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState();
  const [serv, setServ] = useState(null);
  const getData = (cover, images, desc) => {
    setCover(cover);
    setImages(images);
    setDescription(desc);
  };
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/jumperServices/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setServ(res?.data?.data);
        const resp = res.data.data;
        getData(resp.cover, resp.images, resp.description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setSelectedFile(file);
  };
  const [selectedFile2, setSelectedFile2] = useState([]);

  const handleFileChange2 = (e) => {
    console.log(e.target.files);
    const files = e.target.files;

    setSelectedFile2([...selectedFile2, ...files]);
  };
  useEffect(() => {
    console.log({ cover });
  });
  const handleEditService = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("description", description);
    if (newCover) {
      formdata.append("cover", newCover);
    }
    if (newImages) {
      for (let i = 0; i < newImages?.length; i++) {
        formdata.append("images", newImages[i]);
      }
    }

    console.log(formdata.getAll("images"));
    axios
      .put(`https://api.jumpersksa.online/jumperServices/${id}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        toast.success(t("Service Updated Successfully!"));
        const resp = res.data.data;
        getData(resp.cover, resp.images, resp.description);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });
  return (
    <>
      <div>
        <Breadcrumb pageName={t("Edit A Service")} />

        <div>
          <form>
            {serv?.cover !== "undefined" && (
              <>
                {serv?.cover && (
                  <fieldset className="my-10">
                    <p className="text-center font-bold text-sm md:text-base lg:text-lg">
                      {t("update government card")}
                    </p>
                    <div className=" my-5">
                      <img
                        src={`https://api.jumpersksa.online/${cover}`}
                        alt="cover"
                        className="w-full h-50"
                      />
                    </div>

                    <label className="file-input-label bgteal">
                      <span>{t("Choose government card")}</span>
                      <input
                        name="cover"
                        type="file"
                        onChange={(e) => {
                          setNewCover(e.target.files[0]);
                          handleFileChange(e);
                        }}
                        className="file-input"
                      />
                    </label>
                    {selectedFile && (
                      <div>
                        <p className="mt-2">
                          {t("Selected File")}: {selectedFile?.name}
                        </p>
                      </div>
                    )}
                  </fieldset>
                )}
              </>
            )}
            {images?.length > 0 && (
              <>
                <p className="text-center font-bold text-sm md:text-base lg:text-lg my-4">
                  {t("update images")}
                </p>
                <Swiper
                  // install Swiper modules
                  modules={[
                    Navigation,
                    Pagination,
                    // Scrollbar,
                    // A11y,
                    Controller,
                  ]}
                  slidesPerView={3}
                  spaceBetween={40}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  breakpoints={{
                    "@0.00": {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    "@0.75": {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    "@1.00": {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {images?.map((img) => (
                    <SwiperSlide>
                      <img
                        src={`https://api.jumpersksa.online/${img}`}
                        alt="images"
                        className="w-full h-80 object-cover"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <label className="file-input-label bgteal my-4">
                  <span>{t("Choose Images")}</span>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => {
                      setNewImages(e.target.files);
                      handleFileChange2(e);
                    }}
                    name="images"
                    className="file-input bgteal"
                  />
                </label>
                {selectedFile2?.length > 0 && (
                  <div>
                    {selectedFile2.map((s) => (
                      <p className="mt-2">
                        {t("Selected File")}: {s?.name}
                      </p>
                    ))}
                  </div>
                )}
              </>
            )}

            <p className="text-center font-bold text-sm md:text-base lg:text-lg">
              {t("Edit Description")}
            </p>
            <div className="flex justify-center w-full">
              <textarea
                name="description"
                className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                rows="7"
                {...register("description", {
                  // required: true,
                  // minLength: 30,
                  onChange: (e) => {
                    setDescription(e.target.value);
                  },
                })}
                defaultValue={description}
              ></textarea>
            </div>

            <div className="flex justify-center gap-4.5">
              <button
                className="flex justify-center rounded bgteal py-2 px-6 my-4 font-medium text-gray hover:shadow-1"
                type="submit"
                onClick={(e) => {
                  handleEditService(e);
                }}
              >
                {t("Update Service")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditService;
